import { emptySplitApi } from "../../injectEndpoints";

export const MyPropertyManagement = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyPropertyList: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getMyProperties`,
          method: "GET",
          params: {
            userId: data.userId,
          },
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getUserPropertyListUserManagement"],
    }),
    getPropertyTypes: builder.query({
      query: (data) => {
        return {
          url: `/utils/getPropertyTypes`,
          method: "GET",
          // params: {
          //   userId: data.userId,
          // },
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getPropertyTypes"],
    }),
    getCompsetDetails: builder.query({
      query: (data) => {
        return {
          url: `/userDashboardSetting/getCompset`,
          method: "GET",
          params: {
            hId: data.hId,
          },
        };
      },
      // Replace with your actual endpoint
      invalidatesTags: ["getCompsetDetails"],
    }),
    addProperty: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/postNewProperty`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addProperty"],
    }),
    addNewPropertyOta: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateNewPropertyOTAs`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addNewPropertyOta"],
    }),
    addNewPropertyCompset: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateNewPropertyCompset`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addNewPropertyCompset"],
    }),
    addNewPropertyChannelManager: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/updateNewPropertyChannelManager`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addNewPropertyChannelManager"],
    }),
    addMoreProperty: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/addMoreCompset`,
          method: "POST",
          body: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["addMoreProperty"],
    }),
    deleteCompset: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteCompset`,
          method: "PATCH",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteCompset"],
    }),
    updateCMPassword: builder.mutation({
      query: (data) => {
        return {
          url: "automationRoute/updateCMPassword",
          method: "PATCH",
          body: data,
        };
      },
      providesTags: ["updateCMPassword"],
    }),
    deleteCompsetRateRule: builder.mutation({
      query: (data) => {
        return {
          url: `/userDashboardSetting/deleteCompsetRateRule`,
          method: "DELETE",
          params: data,
        };
      },
      // Replace with your actual endpoint
      providesTags: ["deleteCompsetRateRule"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMyPropertyListQuery,
  useGetCompsetDetailsQuery,
  useGetPropertyTypesQuery,
  useAddPropertyMutation,
  useAddNewPropertyOtaMutation,
  useAddNewPropertyCompsetMutation,
  useAddNewPropertyChannelManagerMutation,
  useAddMorePropertyMutation,
  useDeleteCompsetMutation,
  useDeleteCompsetRateRuleMutation,
  useUpdateCMPasswordMutation,
} = MyPropertyManagement;

import React, { useEffect, useState } from "react";
import SettingButton from "../../../components/settingButton/SettingButton";
import Table from "../../../components/table";
import TableButton from "../../../components/settingButton/TableButton";
import Dropdown from "../../../components/dropdown";
import Tableactiontype from "../../../components/table/TableActionTYpe";
import property from "../../../assets/img/property.svg";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../components/inputFeild";
import goibibo from "../../../assets/icons/goibibo.svg";
import EditUserProfile from "./EditUserProfile";
import { useDispatch, useSelector } from "react-redux";
import GlobalModalComponent from "../../../components/modal/newModal";
import { useGethotelIdMutation } from "../../../redux/slices/onBoarding";
import {
  useAddMorePropertyMutation,
  useDeleteCompsetMutation,
  useGetCompsetDetailsQuery,
  useGetMyPropertyListQuery,
} from "../../../redux/slices/settings/myProperties";
import SelectableTab from "../../../components/selectableTab";
import SelectableButton from "../../../components/button/selectableButton";
import { useGetUserDetailsQuery } from "../../../redux/slices/settings/accountSecurity";
import CustomSkeleton from "../../../components/Skeleton";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import {
  CompsetLoaction,
  PropertyChannelManager,
  matchOtaIdAndOtaPId,
  removeDuplicateObjects,
} from "../propertyManagement";
import { toast } from "react-toastify";
import BackdropLoader from "../../../components/newLoader";
import { setLoginData } from "../../../redux/states/login";
import { Dispatch } from "@reduxjs/toolkit";

const MyProperties = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [isAddUserModal, setIsAddUserModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEditCompOpen, setIsEditCompOpen] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  var loginDatas = useSelector((state) => state?.loginData?.activeOta);

  const { data: userDetails } = useGetUserDetailsQuery(
    {
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: MyPropertyList,
    isFetching,
    isLoading: myPropIsLoading,
  } = useGetMyPropertyListQuery(
    {
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: compsetDetails,
    isLoading: compsetIsLoading,
    refetch,
  } = useGetCompsetDetailsQuery(
    {
      hId: CurrentProperty(),
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (myPropIsLoading || compsetIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [myPropIsLoading, compsetIsLoading]);

  const handleAddUser = (row) => {
    setIsAddUserModal(true);
  };

  const handleEditProfile = (propertyData) => {
    setIsEditOpen(true);
    setCurrentProperty(propertyData);
  };
  const closeFunction = () => {
    setIsEditOpen(false);
  };
  const column = [
    {
      name: "Name",
      selector: (row) => row["propertyName"],
      width: "25%",
      cell: (row) => {
        return (
          <div className="flex gap-2 items-center p-1">
            <div>
              <img src={property} alt={""} />
            </div>
            <div className="flex flex-col items-start">
              <p className="font-[600] text-[14px] text-[#131926]">
                {row?.propertyName}
              </p>
              <p className="text-[#0D1321] font-[400] text-[12px]">
                {row?.address?.city}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Users",
      selector: (row) => row["users"],
      width: "10%",
      cell: (row) => {
        return (
          <div className="flex items-center relative w-full">
            <div className="rounded-full overflow-hidden border-[#8833FF] border">
              <img
                className="w-[18px] h-[18px] object-cover"
                src={property}
                alt={""}
                loading="lazy"
              />
            </div>
            <div className="rounded-full overflow-hidden absolute left-2 border-[#8833FF] border">
              <img
                className="w-[18px] h-[18px] object-cover"
                src={property}
                alt={""}
                loading="lazy"
              />
            </div>
            <div className="rounded-full overflow-hidden absolute left-4 border-[#8833FF] border">
              <img
                className="w-[18px] h-[18px] object-cover"
                src={property}
                alt={""}
                loading="lazy"
              />
            </div>
          </div>
        );
      },
    },
    {
      name: "OTAs",
      cell: (row) => {
        return (
          <div>
            {row?.activeOta?.length}/{row?.findAllOta?.length}
          </div>
        );
      },
    },
    {
      name: "Compsets",
      cell: (row) => {
        return <div>{row?.comsetId?.length ? row?.comsetId?.length : 0}</div>;
      },
    },
    {
      name: "Status",
      cell: (row) => {
        return row?.isRetvens === "true" ? (
          <div className="text-[#29CC39] font-[500]">Active</div>
        ) : (
          <div className="text-[#081439] font-[500] text-[14px] cursor-pointer">
            {" "}
            Not Active
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "20%",
      cell: (row) => {
        return (
          <Tableactiontype
            setting={true}
            handleEditText={() => handleEditProfile(row)}
          />
        );
      },
    },
  ];
  // const data = [
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  // ];
  const propManagementOption = ["Compsets", "Users"];
  const userId = UserId();
  const hId = CurrentProperty();
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    async function hello() {
      const response = await fetch(
        `https://rxserver.retvenslabs.com/api/userDashboardSetting/getLoginInfo?userId=${userId}&hId=${hId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = await response.json();
      dispatch(setLoginData(data?.data));
      return data?.data;
    }
    hello();
  }, []);

  return (
    <>
      <BackdropLoader loading={isLoading} />
      {isAddUserModal && (
        <AddUserModal
          setIsAddUserModal={setIsAddUserModal}
          userDetails={userDetails?.data}
        />
      )}
      {MyPropertyList && MyPropertyList?.data && !isLoading && !isFetching ? (
        <PropertyProfile
          isEditCompOpen={isEditCompOpen}
          setIsEditCompOpen={setIsEditCompOpen}
          setIsAddUserModal={setIsAddUserModal}
          setPropertyId={setPropertyId}
          compsetData={compsetDetails?.data}
          propertyData={MyPropertyList?.data}
          userDetails={userDetails?.data}
          propertyId={propertyId}
          onClose={closeFunction}
          refetch={refetch}
        />
      ) : (
        <div className="flex flex-col gap-4">
          {" "}
          <CustomSkeleton height={300} width={"100%"} />
        </div>
      )}

      {/* {!isEditCompOpen && (
        <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
          <SettingButton Title={"My Properties"} hidebackbutton={true} />
          <div className="flex gap-4">
            {propManagementOption.map((item, index) => {
              return (
                <SelectableButton
                  index={index}
                  selectedIndex={selectedIndex}
                  onClick={() => setSelectedIndex(index)}
                  title={item}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      )} */}
    </>
  );
};

export default MyProperties;

const PropertyProfile = ({
  onClose,
  propertyData,
  isEditCompOpen,
  setIsEditCompOpen,
  compsetData,
  userDetails,
  setIsAddUserModal,
  setPropertyId,
  propertyId,
  refetch,
}) => {
  const [currentComp, setCurrentComp] = useState(null);

  const handleEditProfile = (propertyData) => {
    setIsEditCompOpen(true);
    setCurrentComp(propertyData);
  };
  const options = ["Compsets", "Users"];
  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionSelect = (index) => {
    setSelectedOption(index);
  };
  return (
    <>
      {isEditCompOpen ? (
        <EditUserProfile
          propertyData={propertyData}
          propertyId={propertyId}
          onClose={() => setIsEditCompOpen(false)}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%] h-fit overflow-auto">
          {/* <SettingButton
            Title={"Properties Name"}
            text={"Edit"}
            onBack={true}
            handleBackButtonClick={onClose}
            onClick={handleEditProfile}
          /> */}

          {/* <div className="h-[80px] flex items-center bg-[#f6f8fb] p-4 rounded-2xl">
            <div className="flex gap-2 items-start p-1">
              <div>
                <img src={property} alt={""} />
              </div>
              <div className="flex flex-col items-start">
                <p className="font-[600] text-[14px] text-[#131926]">
                  {propertyData && propertyData[0]?.propertyName}
                </p>
                <p className="text-[#0D1321] font-[400] text-[12px]">
                  {propertyData && propertyData[0]?.address?.city}
                </p>
              </div>
            </div>
          </div> */}
          <div className="flex flex-col gap-4">
            {/* <div className="flex justify-start gap-4 pl-2 h-[34px]">
              {options.map((option, index) => (
                <SelectableButton
                  index={index}
                  selectedIndex={selectedOption}
                  onClick={() => setSelectedOption(index)}
                  title={option}
                />
              ))}
            </div> */}
            <div className="">
              {selectedOption !== null && (
                <>
                  {/* {selectedOption === 0 && (
                    <div>
                      <Table1 />
                    </div>
                  )} */}
                  {selectedOption === 0 && (
                    <div>
                      {propertyData && (
                        <Table2
                          setState={setIsEditCompOpen}
                          data={compsetData?.compSetDetails}
                          setPropertyId={setPropertyId}
                          totalOta={
                            propertyData && propertyData?.findAllOta?.length
                          }
                          refetch={refetch}
                        />
                      )}
                    </div>
                  )}
                  {selectedOption === 1 && (
                    <div>
                      <Table3
                        data={userDetails}
                        setIsAddUserModal={setIsAddUserModal}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Table1 = ({ data }) => {
  const methods = useForm({});
  const onSubmit = () => {};
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="w-[100%] bg-[#F6F8FB] p-4 flex gap-4 flex-col rounded-2xl h-[257px]">
            <div className=" flex flex-col gap-4 w-[100%] md:w-[100%] lg:w-[50%]">
              <div className="flex flex-col gap-4">
                <p className="text-[#8794AD] text-[14px] font-[500]">
                  OTA Links
                </p>
                <div className="rounded-lg flex flex-col gap-4 overflow-hidden">
                  {
                    <InputField
                      type={"text"}
                      className={"text-[#0D1321] w-full bg-[#FBFCFD] h-9"}
                      startIcon={goibibo}
                      label={false}
                      placeholder={"Hotelemail@email.com"}
                      id={"goibibo"}
                      name={"goibibo"}
                      controls={methods.control}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const Table2 = ({ setState, data, setPropertyId, totalOta, refetch }) => {
  function removeNullFromArray(arr) {
    return Array.isArray(arr) && arr.filter((item) => item !== null);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [showChannel, setShowChannel] = useState(false);
  const [showAddCompSet, setShowAddCompSet] = useState(false);
  const [compsetData, setCompsetData] = useState([]);
  const [address, setAddress] = useState();
  const [compCity, setCompCity] = useState();
  const [selectedChannelManager, setSelectedChannelManager] = useState();
  const [addMoreCompset] = useAddMorePropertyMutation();
  const [addHotelId] = useGethotelIdMutation();
  const [otaApiData, setOtaApiData] = useState();
  const [compset, setCompset] = useState();
  const [isEdit, setIsEdit] = useState();
  const [editData, setEditData] = useState();
  const [editHIdData, setEditHIdData] = useState();
  const [resetButton, setResetButton] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({});
  const hId = CurrentProperty();
  const [seltectedOtaId, setSelectedOtaId] = useState("");
  const [selectedOTAs, setSelectedOTAs] = useState([]);
  const [CompsetCityCode, setCompsetCityCode] = useState();
  const [deleteCompset, { isLoading: deleteIsloading }] =
    useDeleteCompsetMutation();

  const loginData = useSelector((state) => state?.loginData.activeOtas);

  const userId = UserId();

  const handleDeleteCompset = (index) => {
    const obj = {
      userId: userId,
      hId: hId,
      compsethId: index,
    };
    deleteCompset(obj)
      .unwrap()
      .then((res) => {
        refetch();
        toast.success("Compset deleted successfully!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong!", { position: "bottom-right" });
      });
  };

  const column = [
    {
      name: "Name",
      selector: (row) => row["propertyName"],
      width: "30%",
      cell: (row) => {
        return (
          <div
            className="flex gap-2 items-center p-1 cursor-pointer"
            onClick={() => {
              setPropertyId(row?.hId);
              setState(true);
            }}
          >
            {/* <div>
              <img src={property} alt={""} />
            </div> */}
            <div className="flex flex-col items-start">
              <p className="text-start text-[14px] ">{row?.propertyName}</p>
              <p className=" font-[400] text-[12px] text-start">
                {row?.address?.city}
              </p>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: "Users",
    //   selector: (row) => row["users"],
    //   width: "10%",
    //   cell: (row) => {
    //     return (
    //       <div className="flex items-center relative w-full">
    //         <div className="rounded-full overflow-hidden border-[#8833FF] border">
    //           <img
    //             className="w-[18px] h-[18px] object-cover"
    //             src={property}
    //             alt={""}
    //             loading="lazy"
    //           />
    //         </div>
    //         <div className="rounded-full overflow-hidden absolute left-2 border-[#8833FF] border">
    //           <img
    //             className="w-[18px] h-[18px] object-cover"
    //             src={property}
    //             alt={""}
    //             loading="lazy"
    //           />
    //         </div>
    //         <div className="rounded-full overflow-hidden absolute left-4 border-[#8833FF] border">
    //           <img
    //             className="w-[18px] h-[18px] object-cover"
    //             src={property}
    //             alt={""}
    //             loading="lazy"
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "OTAs",
      cell: (row, index) => {
        return (
          <div>
            {/* {row?.activeOta?.length}/{row?.findAllOta?.length} */}
            {row?.activeOta?.length}/{totalOta}
          </div>
        );
      },
    },
    // {
    //   name: "Compsets",
    //   cell: (row) => {
    //     return <div>{row?.compsetId?.length ? row?.compsetId?.length : 0}</div>;
    //   },
    // },
    {
      name: "Status",
      cell: (row) => {
        return row?.isActive === "true" ? (
          <div className=" font-[500]">Active</div>
        ) : (
          <div className=" font-[500] text-[14px] cursor-pointer">
            {" "}
            Not Active
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "20%",
      cell: (row) => {
        return (
          <Tableactiontype
            onEditClick={() => {
              console.log(row, "aman roe");
              setEditData(row.activeOta);
              setEditHIdData({ hId: row.hId, hotelName: row.propertyName });
              setIsEdit(true);
            }}
            setting={false}
            showDelete={true}
            handleDelete={() => handleDeleteCompset(row.hId)}
          />
        );
      },
    },
  ];
  // const data = [
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  //   {
  //     name: "Delux Room",
  //     users: "6",
  //     ota: "5/5",
  //     compsets: "4/5",
  //     status: "true",
  //   },
  // ];

  // useEffect(() => {
  //   if (compsetData) {
  //     const comp = compsetData.map((com) => {
  //       return {
  //         propertyName: com?.compsetName,
  //         activeOta: selectedOTAs,
  //         cityCode: com?.compsetCity,
  //       };
  //     });
  //     setCompset(comp);
  //   }
  // }, [compsetData]);

  useEffect(() => {
    if (compsetData) {
      const comp = compsetData.map((com) => {
        return {
          propertyName: com?.compsetName,
          activeOta: selectedOTAs,
          cityCode: CompsetCityCode,
        };
      });
      let anyCompset =
        compset && Array.isArray(compset) && compset.length > 0
          ? comp.length === 1
            ? comp
            : compset
          : [];
      anyCompset.push(comp[comp.length - 1]);
      setCompset(anyCompset);

      setSelectedOTAs([]);
    }
  }, [compsetData]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const compBody = {
      hId: hId,
      compsets: removeDuplicateObjects(compset),
    };
    addMoreCompset(compBody)
      .unwrap()
      .then((res) => {
        setCompsetData([]);
        setIsLoading(false);
        toast.success(res?.message, {
          position: "bottom-right",
        });
        refetch();
        setShowAddCompSet(false);
      });
  };
  const handlePost = (data) => {
    const addNewCompsetData = {
      otaDetails: [{ otaId: seltectedOtaId, url: data.url }],
    };
    addHotelId(addNewCompsetData)
      .unwrap()
      .then((res) => {
        toast.success("updated Successfully", { position: "bottom-right" });
        setShowAddCompSet(false);
        setIsEdit(false);
        setResetButton(false);
      })
      .catch((err) => {
        toast.error("There was some problem while updating", {
          position: "bottom-right",
        });
      });
  };

  return (
    <>
      {(isEdit || showAddCompSet) && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-8 mr-3">
          {isEdit && !resetButton && <><p className="text-[20px] font-white">{editHIdData.hotelName} OTA Details</p></>}
            <div className="w-[100%] flex justify-end items-center gap-4 relative -top-10 border-b h-[60px]">
              <button
                type="button"
                className="px-4 bg-red-50 text-red-500 outline outline-red-500 rounded-md hover:bg-red-500 hover:text-white duration-200 ease-in-out transition-all"
                onClick={() => {
                  setShowAddCompSet(false);
                  setIsEdit(false);
                  setResetButton(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 bg-blue-50 text-blue-500 outline outline-blue-500 rounded-md hover:bg-blue-500 hover:text-white duration-200 ease-in-out transition-all"
              >
                Save
              </button>
            </div>

            {showAddCompSet && (
              <CompsetLoaction
                compsetData={compsetData}
                setCompsetData={setCompsetData}
                compAddress={address}
                setCompAddress={setAddress}
                compCity={compCity}
                setCompCity={setCompCity}
                register={methods.register}
                watch={methods.watch}
                setOtaApiData={setOtaApiData}
                selectedOTAs={selectedOTAs}
                setSelectedOTAs={setSelectedOTAs}
                CompsetCityCode={CompsetCityCode}
                setCompsetCityCode={setCompsetCityCode}
              />
            )}
          </form>
        </FormProvider>
      )}
      {!showAddCompSet && !isEdit && (
        <div className="relative  w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
          <div className="flex items-center justify-between">
            <p className="text-[14px] font-[500] ">Compsets</p>
            <div className="flex items-center gap-2">
              {/* <Dropdown selectedItem={"All Users"} /> */}
              <TableButton
                // onClick={}
                title={"Add Compsets"}
                onClick={() => setShowAddCompSet(true)}
              />
            </div>
          </div>
        </div>
      )}

      {isEdit && !resetButton && (
        <FormProvider {...methods}>
          <form
            className=" h-[80vh]  overflow-y-scroll"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="w-[100vw] flex flex-col justify-center gap-2">
              {loginData?.map((item, index) => (
                <div className="w-[100vw]">
                  <div className="flex gap-2 items-center">
                    <img
                      src={item?.otaLogo}
                      loading="lazy"
                      className="h-4"
                      alt="otaLogo"
                    />

                    <div className=" rounded-lg overflow-hidden">
                      <InputField
                        type={"text"}
                        label={false}
                        className={"text-gray-600 bg-[#F6F8FB] h-9"}
                        placeholder={item.otaName}
                        id={`name-${item?.otaName}`}
                        name={`name-${item?.otaName}`}
                      />
                    </div>

                    {editData?.map((items, itemId) => {
                      return (
                        <>
                          {item.otaId === items.otaId &&
                          items.otaPId !== "-" ? (
                            <div onClick={() => setResetButton(true)}>
                              <div
                                onClick={() => {
                                  setSelectedOtaId(item.otaId);
                                  methods.setValue(`name-${item?.otaName}`, "");
                                }}
                                className=" cursor-pointer w-14 p-2 text-white rounded bg-blue-400"
                              >
                                Reset
                              </div>
                            </div>
                          ) : items.otaPId === "-" &&
                            item.otaId === items.otaId ? (
                            <div>
                              <div className="flex justify-center items-center w-14 p-2 cursor-pointer  text-white rounded bg-blue-400">
                                Add
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </form>
        </FormProvider>
      )}
      {!isEdit && data != null && !showAddCompSet && !deleteIsloading && (
        <Table
          columns={column}
          pagination={true}
          data={removeNullFromArray(data)}
        />
      )}
      {deleteIsloading && (
        <div className="w-[100%]">
          <CustomSkeleton height={300} />
        </div>
      )}
      {resetButton && (
        <div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handlePost)}>
              <div className=" rounded-lg overflow-hidden">
                <InputField
                  type={"text"}
                  label={false}
                  className={"text-gray-600 bg-[#F6F8FB] rounded h-9"}
                  placeholder={"Compset Url"}
                  id={"url"}
                  name={"url"}
                />
                <button className="w-fit mt-2 cursor-pointer text-white p-2 bg-blue-400">
                  {" "}
                  Post
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  );
};

const Table3 = ({ data, setIsAddUserModal }) => {
  const column = [
    {
      name: "Name",
      selector: (row) => row["name"],
    },
    {
      name: "Role",
      selector: (row) => row["role"],
    },
    {
      name: "Email Address",
      selector: (row) => row["email"],
    },
    {
      name: "Current Rating",
      selector: (row) => row["status"],
      cell: (row) => {
        if (row["status"] === "true") {
          return <div className="text-[#29CC39] font-[500]">Active</div>;
        } else if (row["status"] === "false") {
          return (
            <div className="text-[#081439] font-[500] text-[14px] cursor-pointer">
              Not Active
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return <Tableactiontype icon={true} />;
      },
    },
  ];
  // const data = [
  //   {
  //     name: "Aman Sharma",
  //     role: "Admin",
  //     email: "emailaddress@email.com",
  //   },
  //   {
  //     name: "Aman Sharma",
  //     role: "Admin",
  //     email: "emailaddress@email.com",
  //   },
  //   {
  //     name: "Aman Sharma",
  //     role: "Admin",
  //     email: "emailaddress@email.com",
  //   },
  // ];
  return (
    <>
      <div className="relative w-[100%] bg-newGradient text-white dark:bg-lightGradient flex flex-col rounded-xl p-4">
        <div className="flex items-center justify-between">
          <p className="text-[14px] font-[500] ">Users</p>
          <div className="flex items-center gap-2">
            <Dropdown selectedItem={"Today"} />
            <TableButton
              title={"Add New"}
              onClick={() => setIsAddUserModal(true)}
            />
          </div>
        </div>
        {data != null && (
          <Table columns={column} pagination={true} data={data} />
        )}
      </div>
    </>
  );
};

const AddUserModal = ({ setIsAddUserModal, userDetails }) => {
  const methods = useForm({});
  const onSubmit = () => {};
  return (
    <>
      <GlobalModalComponent
        hideModal={() => setIsAddUserModal(false)}
        saveBtnTitle={"Save"}
        title={"Add New User to Property"}
      >
        {userDetails &&
          userDetails.map((user, index) => {
            return (
              <div className="flex gap-4 justify-between border border-[#D3D8E1] py-3 text-[#0D1321] px-2 rounded-lg">
                <div className="text-[14px]">{user?.name}</div>
                <div className="flex gap-10 text-[14px]">
                  <div>{user?.designation}</div>
                  <div>{user?.email}</div>
                </div>
                <div className="flex text-[14px] gap-8">
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      className="w-4"
                      id={`view${index}`}
                    />
                    <label
                      htmlFor={`view${index}`}
                      className="font-medium cursor-pointer"
                    >
                      View Only
                    </label>
                  </div>
                  <div className="flex gap-3">
                    <input
                      type="checkbox"
                      className="w-4"
                      id={`admin${index}`}
                    />
                    <label
                      htmlFor={`admin${index}`}
                      className="font-medium cursor-pointer"
                    >
                      Admin
                    </label>
                  </div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" />
                    <div className="relative w-10 h-5 bg-gray-200 peer-focus:outline-none  dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-[18px] after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              </div>
            );
          })}
      </GlobalModalComponent>
    </>
  );
};

const OTAs = () => {
  return <div></div>;
};

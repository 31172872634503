import React, { useEffect, useState } from "react";
import Dropdown from "../../components/dropdown";
import RateSuggestionCard, {
  isDateInThePast,
} from "../../components/rateSuggestionBox/RateSuggestion";
import Calendar from "../../components/calendar";
import RateAnalysis, { BackButton } from "./rateAnalysis";
import { format, subMonths } from "date-fns";
import GlobalModalComponent from "../../components/modal/newModal";
import SelectableTab from "../../components/selectableTab";
import DynamicCounter from "../../components/dynamicCounter/DynamicCounter";
import calendar from "../../../src/assets/icons/calendar.svg";
import { useGetRateSuggestionCalendarQuery } from "../../redux/slices/rateSuggestions";
import { CurrentProperty, UserId } from "../../utils/impVars";
import BackdropLoader from "../../components/newLoader";
import RateSuggestionExcelDownload from "../../components/ExcelDownload/RateSuggestion/rateSuggestionExcelDownload";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineCalendar, AiOutlineTable } from "react-icons/ai";
import formatCurrency from "../../utils/formatCurrency";
import RateSuggestions from "../../components/rateSuggestions";
import { ProgressBar } from "../newRatePulseDashboard";
import CheckBox from "../../components/checkbox/CheckBox";
import { ChannelManagerPasswordModal } from "../newDashboard";
import { navigate } from "@storybook/addon-links";
import { useGetRateAnalysisDynamicRateSingleDayQuery } from "../../redux/slices/dashboard";

function filterDataFromToday(data) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset the time part for accurate comparison

  return data.filter((entry) => {
    const entryDate = new Date(entry.date);
    entryDate.setHours(0, 0, 0, 0); // Reset the time part for accurate comparison
    return entryDate >= today;
  });
}

const RateSuggestion = () => {
  const location = useLocation();
  const [isCalendar, setIsCaleandar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [isToggled, setIsToggle] = useState(false);

  const [selectedType, setSelectedType] = useState({
    label: "Suggestion",
    value: "suggestion",
  });
  useEffect(() => {
    if (location?.state?.navigateDate) {
      setSelectedDate(new Date(location?.state?.navigateDate));
      setSwitchComp(true);
    }
  }, [location?.state?.navigateDate]);
  useEffect(() => {
    if (location?.state?.date) {
      setSelectedDate(new Date(location?.state?.date));
      setSwitchComp(true);
    }
  }, [location?.state?.navigateDate]);
  const {
    data: getRateSuggestionCalendar,
    isLoading: suggestionLoading,
    isFetching: suggestionFetching,
    refetch,
    isError
  } = useGetRateSuggestionCalendarQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate: format(new Date(selectedDate), "yyyy-MM-dd"),
      type: selectedType?.value,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {data:singleDayDynamicRate} = useGetRateAnalysisDynamicRateSingleDayQuery({
    hId:CurrentProperty(),
    userId:UserId(),
    date:format(new Date(selectedDate),'yyyy-MM-dd')
  })
  console.log(isToggled)
const dynamicDate = singleDayDynamicRate && singleDayDynamicRate?.data?.dynamicRates[0]?.rate
  const [switchComp, setSwitchComp] = React.useState(false);
  const [totalDays, setTotalDays] = React.useState(null);
  const [rateSuggestionFilterModal, setRateSuggestionFilterModal] =
    React.useState(false);
  const [filterModalTabIndex, setFilterModalTabIndex] = React.useState(0);
  const today = new Date(selectedDate);
  const currentDay = today.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - currentDay); // Get the first day of the current week

  // Array to hold the dates of the current week
  const daysArray = [];

  // Populate the array with dates of the current week
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(firstDayOfWeek);
    currentDate.setDate(firstDayOfWeek.getDate() + i);
    daysArray.push(currentDate.toDateString());
  }

  useEffect(() => {
    if (suggestionLoading || suggestionFetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suggestionLoading, suggestionFetching]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(
      new Date(selectedDate)?.getFullYear(),
      new Date(selectedDate)?.getMonth()
    );

    const firstDayOfMonth = getFirstDayOfMonth(
      new Date(selectedDate)?.getFullYear(),
      new Date(selectedDate)?.getMonth()
    );

    const calendar = [];
    const today = new Date();

    for (let i = 0; i < firstDayOfMonth; i++) {
      const pastDate = subMonths(
        new Date(
          new Date(selectedDate)?.getFullYear(),
          new Date(selectedDate)?.getMonth()
        ),
        1
      );
      pastDate.setDate(pastDate.getDate() - (firstDayOfMonth - i));
      calendar.push(
        <div key={`empty${i}`} className="calendar-day past-day text-[12px]">
          {pastDate.getDate()}
        </div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(
        new Date(selectedDate && selectedDate)?.getFullYear(),
        new Date(selectedDate && selectedDate)?.getMonth(),
        i
      );

      const isPastDate =
        currentDate < new Date().setDate(new Date().getDate() - 1);
      const isSelected = i === new Date(selectedDate).getDate();
      const className = isSelected
        ? "calendar-day selected text-[12px]"
        : isPastDate
          ? "calendar-day past-day text-[12px] text-[#A8B1C4]"
          : "calendar-day text-black text-[12px]";
      calendar.push(
        <div
          className="flex justify-center cursor-pointer "
          onClick={
            isPastDate
              ? () => null
              : () => {
                  setSelectedDate(
                    new Date(
                      new Date(selectedDate)?.getFullYear(),
                      new Date(selectedDate)?.getMonth(),
                      i
                    )
                  );
                  setSwitchComp(true);
                }
          }
        >
          <RateSuggestionCard
            isSelected={new Date(selectedDate).getDate() === i ? true : false}
            isTrue={true}
            number={i}
            rate={
              getRateSuggestionCalendar?.data[i - 1]?.rate
                ? getRateSuggestionCalendar?.data[i - 1]?.rate
                : "--"
            }
            suggestedRate={
              getRateSuggestionCalendar?.data[i - 1]?.suggestedRate
                ? getRateSuggestionCalendar?.data[i - 1]?.suggestedRate
                : "--" || getRateSuggestionCalendar?.data[i - 1]?.dynamicRate
                  ? getRateSuggestionCalendar?.data[i - 1]?.dynamicRate
                  : "--"
            }
            showOccupancy={true}
            height={110}
            occupancyPercentage={
              getRateSuggestionCalendar?.data[i - 1]?.occupancy
            }
            isPastDate={isPastDate}
            selectedType={selectedType?.label}
            overRideRate={getRateSuggestionCalendar?.data[i - 1]?.overRideRate}
            isDynamic={selectedType?.label === "Dynamic"}
          />
        </div>
      );
    }

    return calendar;
  };

  React.useEffect(() => {
    const getDaysInMonth = (year, month) => {
      return new Date(year, month + 1, 0).getDate();
    };
    if (selectedDate) {
      setTotalDays(
        getDaysInMonth(
          new Date(selectedDate)?.getFullYear(),
          new Date(selectedDate)?.getMonth()
        )
      );
    }
  }, [selectedDate]);
  const typesArray = ["Room Inventory", "Plan Rates", "Restrictions"];
console.log(isToggled)
  const columns = React.useMemo(
    () => [
      {
        Header: "Arrival Date",
        accessor: "date",
        width: "100%",
        Cell: ({ value }) => {
          return (
            <div className=" flex items-center dark:text-[#D3D8E1]">
              {value && format(new Date(value), "dd-MMM-yy")}
            </div>
          );
        },
      },

      {
        Header: "Occupancy ",
        accessor: "occupancy",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" flex items-center dark:text-white gap-2  justify-between">
            <div style={{  borderRadius: "5px", width: "45px" }}>
              {value}%
            </div>
            <ProgressBar percentage={value} width={100} />
          </div>
        ),
      },
      {
        Header: "Current Rate",
        accessor: "rate",
        width: "100%",
        Cell: ({ value }) => (
          <div className=" flex  items-center justify-start w-full ">
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        Header: isToggled ? "Dynamic Rate" : "Suggested Rate",
        accessor: isToggled ? "dynamicRate": "suggestedRate",
        width: "100%",
        Cell: ({ row }) => (
          <div className=" text-green-500 flex items-center justify-start w-full  ">
            {isToggled ? formatCurrency(row?.original?.dynamicRate) : formatCurrency(row?.original?.suggestedRate)}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        width: "100%",
        Cell: ({ row }) => {
          return (
            <div className=" h-full flex justify-around items-center gap-4 ">
              <div
                onClick={() => {
                  setSelectedDate(row?.original?.date);
                  setShowModal(true);
                  setSwitchComp(true);
                }}
                className=" w-24 h-8 bg-[#1D2D44] cursor-pointer dark:bg-[#1D2D44] text-white rounded-lg text-center text-sm grid items-center "
              >
                Push Rate
              </div>
              <div className=" lg:mr-2 md:mr-0 mr-0">
                {/* <img src={arrow} /> */}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  // const formattedRateSuggestion = getRateSuggestionCalendar?.data?.map((item) => {
  //   return {
  //     ...item,
  //     date: format(new Date(item?.date), 'dd-MM-yyyy')
  //   };
  // });

  const toggleButton = () => {
    setIsToggle(!isToggled);
  };

  useEffect(() => {
    if (isToggled) {
      setSelectedType({ label: "Dynamic", value: "dynamic" });
    } else {
      setSelectedType({ label: "Suggestion", value: "suggestion" });
    }
  }, [isToggled]);

 
const navigate =  useNavigate()

console.log(getRateSuggestionCalendar)
  return (
    <div>
      <BackdropLoader loading={isLoading} />
      {rateSuggestionFilterModal && (
        <BulkUpdateModal
          filterModalTabIndex={filterModalTabIndex}
          setFilterModalTabIndex={setFilterModalTabIndex}
          setOverviewFilterModal={setRateSuggestionFilterModal}
          typesArray={typesArray}
        />
      )}
      {!switchComp && (
        <div>
          <div className="flex py-2 px-4 justify-between w-[100%]">
            <div className="text-white dark:text-black flex items-center gap-2  font-medium text-[17px]">
              {/* <BackButton mainDashboard={true} /> */}
              Rate Suggestions Calendar
            </div>
            <div className="flex gap-2 md:flex-row flex-col items-center">
              <RateSuggestionExcelDownload
                apiData={getRateSuggestionCalendar?.data}
              />
              <div className="ml-2">
             
                <div className="flex gap-2 items-center text-white dark:text-black">
                  <div className="text-sm">Suggestion</div>
                  <CheckBox
                    checked={isToggled}
                    onChange={toggleButton}
                    name={"toggle"}
                  />
                  <div className="text-sm">Dynamic</div>
                </div>
              </div>
              <div className="ml-2">
                <Calendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  disablePastDays={true}
                  enableOneYearFromNow={true}
                  // onlyPastDaysSelectable={false}
                  customAlert={
                    "Only 1 year future date are enable from current date."
                  }
                  // height={36}
                />
              </div>
              {!isCalendar ? (
                <div
                  onClick={() => setIsCaleandar(true)}
                  className="cursor-pointer bg-[#d6e0ff] py-1 px-2 text-[#081439] rounded-lg text-sm flex items-center gap-2 justify-center"
                >
                  Calendar view <AiOutlineCalendar size={18} />
                </div>
              ) : (
                <div
                  onClick={() => setIsCaleandar(false)}
                  className="cursor-pointer bg-white/30 dark:bg-customSubDarkBg py-1 px-2 text-white rounded-lg text-sm flex items-center gap-2 justify-center"
                >
                  Table view <AiOutlineTable size={18} />
                </div>
              )}
            </div>
          </div>
          {isCalendar ? (
            <div className="relative">
         {   <div className={`${isError && 'blur-md bg-white/50 mx-5 z-50'} px-2 pl-2.5`}>
              <div className="flex py-2 mt-4 flex-wrap justify-between w-[100%] flex-row">
                {daysArray.map((day, index) => (
                  <div className="flex w-[14%] dark:text-[#7D889E] text-[12px] text-[#8794AD] justify-center">
                    <div>{day.split(" ")[0]}</div>
                  </div>
                ))}
              </div>
              <div className="grid gap-2 mx-2 grid-cols-3 md:grid-cols-7">
                {renderDays()}
              </div>
            </div>}
            {
                isError && <div className="absolute top-[40%] left-[35%] z-50">
                  <div className="flex items-center bg-white p-5 rounded-md justify-center flex-col">
                  <div className="text-lg text-red-500">Please setup your dynamic price settings.</div>
                  <div onClick={()=>navigate('/Settings',{state:{address:{
                    case1:"dynamic_pricing"
                  }}})} className="w-fit my-4 cursor-pointer px-4 py-2 text-white bg-blue-600 text-sm rounded-lg">Go to settings.</div></div>
                  </div>
              
              }
            </div>
          
          ) : (
            // <div className="p-4 bg-white rounded-lg mr-3">
            //   <div className="grid py-2 grid-cols-6 text-sm text-neutral-light-17 px-2 border-b">
            //     <div>Date</div>
            //     <div>OTA</div>
            //     <div>Rate</div>
            //     <div>Occupancy</div>
            //     <div>Suggested Rate</div>
            //     <div>Override Rate</div>
            //   </div>
            //   <div className="max-h-[600px] overflow-scroll">
            //     {
            //      getRateSuggestionCalendar && getRateSuggestionCalendar?.data && getRateSuggestionCalendar?.data?.map((item,index)=>{
            //       return <div className={`hover:bg-gray-100 transition-all ease-in-out duration-200 hover:border-l-4 hover:border-l-blue-500 grid grid-cols-6 px-2 py-4 text-sm rounded-lg border-b`}>
            //         <div>{format(new Date(item?.date),'dd-MMM-yy')}</div>
            //         <div>{item?.otaName}</div>
            //         <div>{formatCurrency(item?.rate)}</div>
            //         <div>{item?.occupancy}</div>
            //         <div className="text-green-500 ">{formatCurrency(item?.suggestedRate)}</div>
            //         <div>{formatCurrency(item?.overRideRate)}</div>
            //       </div>
            //      })
            //     }
            //     </div>
            //   </div>
            <div className="m-4">
             <RateSuggestions
              isPermission={true}
              title={"R-Suggestion Table View"}
              compCol={columns}
              compData={
                getRateSuggestionCalendar &&
                filterDataFromToday(getRateSuggestionCalendar?.data)
              }
              isPagination={true}
              showDescription={false}
            />
            </div>
           
          )}
        </div>
      )}
      {switchComp && (
        <RateAnalysis
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          hideToggle={() => setSwitchComp(false)}
          totalDays={totalDays}
          apiData={getRateSuggestionCalendar}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          options={[
            { label: "Suggestion", value: "suggestion" },
            { label: "Dynamic", value: "dynamic" },
          ]}
          showModal={showModal}
          setShowModal={setShowModal}
          refetch={refetch}
          isToggled={isToggled}
          toggleButton={toggleButton}
          setIsToogle={setIsToggle}
          dynamicRate={dynamicDate}
          dynamicData={singleDayDynamicRate}
        />
      )}
      
    </div>
  );
};

export default RateSuggestion;

const BulkUpdateModal = ({
  setOverviewFilterModal,
  typesArray,
  filterModalTabIndex,
  setFilterModalTabIndex,
}) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  return (
    <GlobalModalComponent
      hideModal={() => setOverviewFilterModal(false)}
      saveBtnTitle={"Update"}
      title={"Overview Settings"}
    >
      <div className="flex gap-2">
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">Time Period</div>
          <div className=" ">
            <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
          </div>
        </div>
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">Room Type</div>
          <div className=" ">
            <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
          </div>
        </div>
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">Rate Plan</div>
          <div className=" ">
            <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">From</div>
          <div className=" h-[36px] w-full rounded-md bg-[#F6F8FB] px-3 py-1 flex justify-between items-center ">
            <div className="font-medium  text-sm ">Select Rate Plan</div>
            <div className="">
              <img src={calendar} />
            </div>
          </div>
        </div>
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">To</div>
          <div className=" h-[36px] w-full rounded-md bg-[#F6F8FB] px-3 py-1 flex justify-between items-center">
            <div className=" font-medium  text-sm"> Select Rate Plan</div>
            <div>
              <img src={calendar} />
            </div>
          </div>
        </div>
        <div className="flex flex-col  mb-2  w-[100%]">
          <div className="w-[100%] text-[#59647B] text-[14px]">
            Apply to Days
          </div>
          <div className="">
            <Dropdown width={400} height={36} backgroundColor={"#F6F8FB"} />
          </div>
        </div>
      </div>
      <SelectableTab
        typesArray={typesArray}
        selectedIndex={filterModalTabIndex}
        setSelectedIndex={setFilterModalTabIndex}
      />
      <div className="flex w-full mt-4">
        <div className="flex h-34  flex-col w-full">
          <div className=" h-full text-[#59647B] text-[14px] w-full">
            Block Inventory
          </div>
          <div className=" h-full  text-[#59647B] text-[14px] w-full">
            Add Inventory
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <DynamicCounter />
          <DynamicCounter />
        </div>
      </div>
    </GlobalModalComponent>
  );
};

export const ProgressDoughnut = ({
  percentage,
  width,
  height,
  textColor,
  fontSize,
}) => {
  const radius = 50;
  const strokeWidth = 12;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  let color;
  if (percentage <= 33) {
    color = "#ff7979"; // Red for 0-33%
  } else if (percentage <= 66) {
    color = "#ffbe76"; // Orange for 34-66%
  } else {
    color = "#badc58"; // Green for 67-100%
  }

  return (
    <div className="flex items-center gap-2">
      <svg width={width} height={height} viewBox="0 0 120 120">
        <circle
          className="progress-ring__background"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={60}
          cy={60}
        />
        <circle
          className="progress-ring__circle"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: progress }}
          fill="transparent"
          r={radius}
          cx={60}
          cy={60}
        />
      </svg>
      <div
        className={`text-[${fontSize}]  font-medium`}
        style={{ color: color }}
      >
        {percentage}%
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Table from "../../../../components/table";
import SettingButton from "../../../../components/settingButton/SettingButton";
import Dropdown from "../../../../components/dropdown";
import Tableactiontype from "../../../../components/table/TableActionTYpe";
import adult from "../../../../assets/icons/adult.svg";
import child from "../../../../assets/icons/child.svg";
import TableButton from "../../../../components/settingButton/TableButton";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../../../components/inputFeild";
import DynamicCounter from "../../../../components/dynamicCounter/DynamicCounter";
import CheckBox from "../../../../components/checkbox/CheckBox";
import {
  useAddRatePlanMutation,
  useDeleteRatePlanMutation,
  useEditRatePlanMutation,
  useGetRatePlanChargeQuery,
  useGetRatePlanQuery,
  useGetRoomTypeMealTypeQuery,
} from "../../../../redux/slices/settings/channelManager/ratePlan";
import { toast } from "react-toastify";
import { CurrentProperty, UserId } from "../../../../utils/impVars";
import GenerateShortCode from "../../../../utils/shortCode";
import CustomSkeleton from "../../../../components/Skeleton";

const RatePlan = ({ handleBackButtonClick }) => {
  const [isAddRatePlan, setIsAddRatePlan] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [ratePlanData, setRateplanData] = useState();
  const {
    data: ratePlan,
    isLoading,
    isFetching,
    refetch,
  } = useGetRatePlanQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const hId = CurrentProperty();
  const [addRatePlan] = useAddRatePlanMutation();
  const [editRatePlan] = useEditRatePlanMutation();
  const [deleteRatePlan] = useDeleteRatePlanMutation();

  const handleAddRatePlanClick = () => {
    setIsAddRatePlan(true);
  };
  const handleDelete = (ratePlanId) => {
    deleteRatePlan({ ratePlanId: ratePlanId, hId: hId })
      .then((res) => {
        toast.success("Rate Plan Delete Successfully!", {
          position: "bottom-right",
        });
        refetch();
      })
      .catch((err) => {
        toast.error("Rate Plan Not Deleted!", {
          position: "bottom-right",
        });
      });
  };

  const column = [
    {
      name: "Rate Plan Name",
      selector: (row) => row["ratePlanName"],
    },
    {
      name: "Rate Rule",
      selector: (row) => row["ratePlanCharge"]["rateRule"],
    },
    {
      name: "Adult & Child",
      cell: (row) => {
        return (
          <div className="flex gap-2">
            <div className="flex gap-2 items-center">
              <img src={adult} alt="" />
              <p className="font-[700]">{`1-${row?.occupancy?.maxAdult}`}</p>
              <img src={child} alt="" />
              <p className="font-[700]">{`0-${row?.occupancy?.maxChild}`}</p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Occupancy",
      selector: (row) => row["occupancy"]['baseOccupancy'],
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <Tableactiontype
            onViewClick={() => {
              setRateplanData(row);
              setIsView(true);
              setIsEdit(true);
            }}
            onEditClick={() => {
              setRateplanData(row);
              setIsEdit(true);
            }}
            showDelete={true}
            handleDelete={() => handleDelete(row?.ratePlanId)}
            refetch={refetch}
          />
        );
      },
    },
  ];

 

 
  return (
    <>
      {isAddRatePlan || isEdit || isView ? (
        <AddRatePlan
          onClose={() => {
            setIsAddRatePlan(false)
            setIsEdit(false);
            setIsView(false);
          }}
          addRatePlan={addRatePlan}
          data={ratePlanData}
          isView={isView}
          isEdit={isEdit}
          editRatePlan={editRatePlan}
          refetch={refetch}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%]">
          <SettingButton
            Title={"Rate Plan"}
            table={true}
            handleBackButtonClick={handleBackButtonClick}
          />
          <div className="flex gap-2 w-[100%]">
            <div className="relative w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient  flex flex-col rounded-xl p-4">
              <div className="flex items-center justify-between">
                <p className="text-[14px] font-[500]">Rate Plan</p>
                <div className="flex items-center gap-2">
                  {/* <Dropdown selectedItem={"Rate"} /> */}
                  <TableButton
                    title={"Add New"}
                    onClick={handleAddRatePlanClick}
                  />
                </div>
              </div>
              <div className="h-[400px] scroll-container">
                {ratePlan?.data != null && !isLoading &&
                  !isFetching ? (
                  <Table columns={column} pagination={true} data={ratePlan?.data} />
                ) : (
                  <CustomSkeleton height={400} width={"100%"} />
                )
                }
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RatePlan;

const AddRatePlan = ({ onClose, addRatePlan, data: editData,
  isView,
  isEdit,
  editRatePlan,
  refetch, }) => {
  const methods = useForm({});
  const { data: ratePlanChargeRule } = useGetRatePlanChargeQuery(
    {
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: getRoomTypeMealType } = useGetRoomTypeMealTypeQuery(
    {
      hId: CurrentProperty(),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [selectedChargeRule, setSelectedChargeRule] = useState(null);
  const [maxOccupancy, setMaxOccupancy] = useState(0);
  const [baseOccupancy, setBaseOccupancy] = useState(0);
  const [maxAdult, setMaxAdult] = useState(0);
  const [maxChild, setMaxChild] = useState(0);
  const [mealPlanCharge, setMealPlanCharge] = useState(0);
  const [additionalAdults, setAdditionlAdults] = useState(0);
  const [additionalChild, setAdditionlChild] = useState(0);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedMealplan, setSelectedMealplan] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const hotelId = CurrentProperty()
  const onSubmit = async (data) => {
    const obj = {
      hId: hotelId,
      ratePlanDetails: {
        roomTypeId: selectedRoomType?.value,
        ratePlanName: data?.ratePlanName,
        ratePlanShortCode: data?.shortCode,
        mealPlanId: selectedMealplan?.value,
        baseOccupancy: baseOccupancy,
        maxOccupancy: maxOccupancy,
        maxChild: maxChild,
        maxAdult: maxAdult,
        chargeDetails: {
          mealPlancharge: mealPlanCharge,
          chargeRule: selectedChargeRule?.value,
        },
        ratePlanCharge: {
          rateRule: selectedChargeRule?.value,
        },
        extraOccupancyCharges: {
          AdditionalAdultCharges: additionalAdults,
          AdditionalChildCharges: additionalChild,
        },
      },
    };
    const editParams = {
      hId: hotelId,
      ratePlanId: editData .ratePlanId
      ,
    };

    if (isEdit) {
      editRatePlan({ obj, editParams })
        .unwrap()
        .then((res) => {
          toast.success("Rate Plan Updated Successfully!", {
            position: "bottom-right",
          });
          refetch();
          onClose();
        })
        .catch((err) => {
          onClose();
        });
    } else {
      addRatePlan(obj)
        .unwrap()
        .then((res) => {
          toast.success("Rate Plan created successfully!", { position: "bottom-right" });
          refetch();
          onClose();
        })
        .catch((err) => {
          toast.error(err?.message, { position: "bottom-right" });
          onClose();
        });
    }
  };

  const chargeRuleOptions =
    ratePlanChargeRule &&
    ratePlanChargeRule?.data.map((item) => {
      return {
        label: item.chargeRuleName,
        value: item.chargeId,
      };
    });

  useEffect(() => {
    if (editData) {
      methods.setValue("ratePlanName", editData?.ratePlanName);
      methods.setValue("shortCode", editData?.ratePlanShortCode);
      setMealPlanCharge(editData?.chargeDetails?.mealPlancharge);
      setSelectedChargeRule({
        label: editData?.chargeDetails?.chargeRule,
        value: editData?.chargeDetails?.chargeRule,
      });
    }
  }, [editData]);

  useEffect(() => {
    if (methods.watch('ratePlanName')) {
      methods.setValue('shortCode', GenerateShortCode(methods.watch('ratePlanName')))
    }

  }, [methods.watch('ratePlanName')])
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
          <SettingButton
            text={"Save"}
            Title={"Add New Rate Plan"}
            onClose={onClose}
            handleBackButtonClick={onClose}
            table={true}
            isView={isView}
          />
          <div className="flex gap-4 w-[100%] p-2 flex-col overflow-y-auto">
            <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-white dark:text-[#131926] text-[13px]">Rate Plan Details</p>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-white dark:text-[#131926] text-[12px]">Rate Plan Name</p>
                <div className="w-1/3 ">
                  <InputField
                    type={"text"}
                    className={"text-gray-600 w-full bg-[#F6F8FB] h-9  rounded-lg"}
                    label={false}
                    placeholder={"Enter Rate Plan Name"}
                    id={"ratePlanName"}
                    name={"ratePlanName"}
                    controls={methods.control}
                    rules={{ required: "Rate Plan Name is required" }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-white dark:text-[#131926] text-[12px]">Short Code</p>
                <div className="w-1/3 ">
                  <InputField
                    type={"text"}
                    className={"text-gray-600 w-full bg-[#F6F8FB] h-9 rounded-lg"}
                    label={false}
                    placeholder={"Rate Plan Short Code"}
                    id={"shortCode"}
                    name={"shortCode"}
                    controls={methods.control}
                    rules={{ required: "Rate Plan Short Code is required" }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-white dark:text-[#131926] text-[12px]">Room Type</p>
                <div className="w-1/3  rounded-lg">
                  <Dropdown
                    selectedItem={selectedRoomType?.label}
                    setSelectedItems={setSelectedRoomType}
                    options={getRoomTypeMealType?.data?.roomTypes}
                    width={450}
                    height={36}
                    backgroundColor={"white"}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-white dark:text-[#131926] text-[12px]">Meal Plan</p>
                <div className="w-1/3  rounded-lg">
                  <Dropdown
                    selectedItem={selectedMealplan?.label}
                    setSelectedItems={setSelectedMealplan}
                    options={getRoomTypeMealType?.data?.mealType}
                    width={450}
                    height={36}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-white dark:text-[#131926] text-[13px]">Occupancy</p>
              <p className="text-white dark:text-[#131926] flex gap-4 items-center text-[13px]">
                Edit Default Room Occupancy
                <span>
                  <CheckBox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </span>
              </p>
              <div
                className="w-[100%]"
                style={{
                  opacity: isChecked ? 1 : 0.5,
                  cursor: isChecked ? "auto" : "not-allowed",
                }}
              >
                <div className="flex gap-4 w-1/2">
                  <div className=" w-7/12 flex flex-col justify-center gap-2">
                    <p className="text-white dark:text-[#131926] text-[12px]">Base Occupancy</p>
                    <DynamicCounter
                      initialValue={8}
                      count={baseOccupancy}
                      isChecked={isChecked}
                      setCount={setBaseOccupancy}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white dark:text-[#131926] text-[12px]">Max Occupancy</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxOccupancy}
                      isChecked={isChecked}
                      setCount={setMaxOccupancy}
                    />
                  </div>
                </div>
                <div className="flex gap-4 w-1/2">
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white dark:text-[#131926] text-[12px]">Max Adults</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxAdult}
                      isChecked={isChecked}
                      setCount={setMaxAdult}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col justify-center gap-2">
                    <p className="text-white dark:text-black text-[12px]">Max Child</p>
                    <DynamicCounter
                      initialValue={8}
                      count={maxChild}
                      isChecked={isChecked}
                      setCount={setMaxChild}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col gap-4 w-[100%]">
              <p className="text-[#131926] text-[13px]">Charge Details</p>
              <div className="flex gap-4 w-1/2">
                <div className="w-1/2 flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Meal Plan Charge</p>
                  <DynamicCounter
                    initialValue={"₹ 3,000.00"}
                    count={mealPlanCharge}
                    setCount={setMealPlanCharge}
                  />
                </div>
                <div className="w-1/2 flex flex-col justify-center gap-2">
                  <p className="text-[#8794AD] text-[12px]">Charge Rule</p>
                  <Dropdown selectedItem={selectedChargeRule?.label} setSelectedItems={setSelectedChargeRule} options={chargeRuleOptions} setting={true} />
                </div>
              </div>
            </div> */}
            <div className="flex flex-col gap-4 w-[100%]">
              <p className=" text-white dark:text-black text-[13px]">Charge Details</p>
              <div className="flex gap-4 w-1/2">
                <div className="w-1/2 flex flex-col justify-center gap-2">
                  <p className="text-white dark:text-black text-[12px]">Charge Rule</p>
                  <Dropdown
                    selectedItem={selectedChargeRule?.label}
                    width={300}
                    setSelectedItems={setSelectedChargeRule}
                    options={chargeRuleOptions}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 w-[100%]" style={{
              opacity: isChecked ? 1 : 0.5,
              cursor: isChecked ? "auto" : "not-allowed",
            }}>
              <p className="text-[#131926] text-[13px]">
                Extra Occupancy Charges
              </p>
              <div className="flex gap-4 w-1/2">
                <div className="w-1/2 flex flex-col justify-center gap-2">
                  <p className="text-white dark:text-black">Additional Adult</p>
                  <DynamicCounter initialValue={"₹ 10,000.00"} setCount={setAdditionlAdults} count={additionalAdults} isChecked={isChecked} />
                </div>
                <div className="w-1/2 flex flex-col justify-center gap-2">
                  <p className="text-white dark:text-black text-[12px]">Additional Child</p>
                  <DynamicCounter initialValue={"₹ 10,000.00"} setCount={setAdditionlChild} count={additionalChild} isChecked={isChecked} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

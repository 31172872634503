import React, { useEffect, useRef, useState } from "react";
import HotelAnalysis from "../../components/HotelAnalysis/analysis";
import BarChartComponent from "../../components/hotelOverview/barChartComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BackdropLoader from "../../components/newLoader";
import { useGetHotelPerformanceQuery } from "../../redux/slices/dashboard";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { format } from "date-fns";
import { FiDownload } from "react-icons/fi";
import TimeRange from "../../components/timeRange";
import useIntelligenceDateRange from "../../utils/dateRange";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import RevensHey from "../../assets/icons/revens.svg";
import HotelPerformanceTable from "../../components/HotelAnalysis/HotelPerformanceTable";
import { useIsIpad } from "../../utils/isIpad";
import increase from "../../assets/icons/increase.svg";
import down from "../../assets/icons/decrease.svg";
import { calculatePercentage } from "../../utils/calculatePercentage";
// Adjust the path accordingly
const RadialProgress = ({ percentage, size = 100, strokeWidth = 10 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const getGradient = () => {
    if (percentage < 50) {
      return "url(#gradient-red-yellow)";
    } else if (percentage < 75) {
      return "url(#gradient-yellow-green)";
    } else {
      return "url(#gradient-green)";
    }
  };

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <linearGradient
          id="gradient-red-yellow"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" stopColor="red" />
          <stop offset="100%" stopColor="yellow" />
        </linearGradient>
        <linearGradient
          id="gradient-yellow-green"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" stopColor="yellow" />
          <stop offset="100%" stopColor="green" />
        </linearGradient>
        <linearGradient id="gradient-green" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="green" />
          <stop offset="100%" stopColor="darkgreen" />
        </linearGradient>
      </defs>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="#e6e6e6"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={getGradient()}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={size / 5} // Adjust font size as needed
        fill="#333"
      >
        {percentage}%
      </text>
    </svg>
  );
};
const HotelOverview = () => {
  const chartRef = useRef();
  const dateRange = useIntelligenceDateRange();
  const [period2ShowDropDown, setPeriod2ShowDropDown] = useState(false);
  const [period1ShowDropDown, setPeriod1ShowDropDown] = useState(false);
  const isIpad = useIsIpad();
  const currentDate = new Date();
  const ref = useRef();
  const ref2 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPeriodOneCalender(false);
        setPeriod1ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setPeriodTwoCalender(false);
        setPeriod2ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);
  const [startEndPeriod1, setStartEndPeriod1] = useState([
    {
      startDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        1
      ),
      endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      key: "range1",
    },
  ]);
  const [periodOneCalender, setPeriodOneCalender] = useState(false);
  const [periodTwoCalender, setPeriodTwoCalender] = useState(false);
  const [startEndPeriod2, setStartEndPeriod2] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  const [inputValue, setInputValue] = useState(
    `${format(new Date(startEndPeriod1[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod1[0].endDate), "dd-MMM-yy")}`
    // `${format(new Date(startEndPeriod1[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod1[0].endDate), "dd-MMM-yy")}`
  );
  const [inputValuePeriod2, setInputValuePeriod2] = useState(
    `${format(new Date(startEndPeriod2[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod2[0].endDate), "dd-MMM-yy")}`
  );
  const [FirstPeriod, setFirstPeriod] = useState([
    {
      startDate: new Date(
        // currentDate.getFullYear() - 1,
        // currentDate.getMonth(),
        // 1
        "2024-08-08"
      ),
      endDate: new Date(
        // currentDate.getFullYear() - 1,
        // currentDate.getMonth() + 1,
        // 0
        "2024-08-31"
      ),
      key: "range1",
    },
  ]);
  const [SecondPeriod, setSecondPeriod] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  const { data: hotelPerformanceData, isLoading: hotelPerformanceIsLoading } =
    useGetHotelPerformanceQuery({
      userId: UserId(),
      hId: CurrentProperty(),

      startDate: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
      endDate: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
      // startDate1: format(new Date(SecondPeriod[0].startDate), "yyyy-MM-dd"),
      // endDate1: format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd"),
    });
  const [isLoading, setIsLoading] = useState(false);
  const generatePDF = () => {
    setIsLoading(true);

    // Ensure all fonts are loaded before rendering
    document.fonts.ready.then(() => {
      html2canvas(chartRef.current, {
        scale: 2,
        useCORS: true, // Use this if your content includes images from other domains
        logging: false, // Disable logging for performance
        windowWidth: document.documentElement.offsetWidth, // Ensure canvas matches the window size
      }).then(async (canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7); // Compress image, 0.7 is the quality level (0-1)
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 210; // A4 width: 210mm
        const pageHeight = 297; // A4 height: 297mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(
          imgData,
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        ); // Use 'FAST' compression
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "JPEG",
            0,
            position,
            imgWidth,
            imgHeight,
            undefined,
            "FAST"
          ); // Apply compression to new pages
          heightLeft -= pageHeight;
        }

        await pdf.save("hotel_overview.pdf");
        setIsLoading(false);
      });
    });
    setIsLoading(false);
  };
  const handleSave = () => {
    setFirstPeriod(startEndPeriod1);
    setSecondPeriod(startEndPeriod2);
  };

  return (
    <div ref={chartRef} className="p-8 flex flex-col gap-4">
      <BackdropLoader loading={hotelPerformanceIsLoading} />
      <div className="flex justify-between text-white dark:text-black">
        <div>
          <h1 className="font-bold">Welcome to Hotel Analysis</h1>
          <p>
            {" "}
            This area tells you how your hotel performed for a today and same time last year.
          </p>


      <div className="w-[100%] flex flex-col px-[20px]  text-white dark:text-black">
        <p className="font-bold"> Period 1: Last Month</p>
        <p className="font-bold"> Period 2: Same Time Last Year</p>
      </div>
        </div>
        <div className="flex gap-4 items-center">
          {/* <div className="flex justify-end items-center !important gap-2 w-[100%]">
                <div className="h-5 w-5 bg-[#3361FF] rounded-full"></div>
                <div className="text-sm w-full mx-auto">Period 1</div>
                <div className="flex justify-end" ref={ref}>
                    <TimeRange
                    period={startEndPeriod1}
                    setPeriod={setStartEndPeriod1}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    left={"-300px"}
                    setCalendarOpen={setPeriodOneCalender}
                    calendarOpen={periodOneCalender}
                    isFuture={false}
                    showDropDown={period1ShowDropDown}
                    setShowDropDown={setPeriod1ShowDropDown}
                    minDate={new Date(dateRange?.fromDate) || new Date()}
                    maxDate={new Date(dateRange?.toDate) || new Date()}
                    />
                </div>
                </div>
                <div className="flex justify-between md:justify-end items-center gap-2 w-[100%]">
                <div className="h-5 w-5 cursor-pointer bg-[#59647B] rounded-full"></div>
                <div className="text-sm w-full">Period 2</div>
                <div className="flex justify-end " ref={ref2}>
                    <TimeRange
                    period={startEndPeriod2}
                    setPeriod={setStartEndPeriod2}
                    inputValue={inputValuePeriod2}
                    setInputValue={setInputValuePeriod2}
                    left={"-380px"}
                    setCalendarOpen={setPeriodTwoCalender}
                    calendarOpen={periodTwoCalender}
                    isFuture={false}
                    showDropDown={period2ShowDropDown}
                    setShowDropDown={setPeriod2ShowDropDown}
                    minDate={new Date(dateRange?.fromDate) || new Date()}
                    maxDate={new Date(dateRange?.toDate) || new Date()}
                    />
                </div>
                </div>
                <div
                onClick={handleSave}
                className="bg-blue-50 w-fit md:w-full outline outline-blue-500 rounded-lg px-4 flex justify-between items-center mt-1.5 h-[30px] text-blue-500 hover:bg-blue-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer"
                >
                Update
                </div> */}
          {/* <div className="text-gray-400">
            <div className="flex items-center justify-center gap-2">
                {" "}
                <div className="h-4 w-4   leading-tight  rounded-md bg-blue-600"></div>{" "}
                <p>1 Jan 24 - 31 Dec 25 </p>
            </div>
            <div className="flex items-center  justify-center gap-2">
                {" "}
                <div className="h-4 w-4  leading-snug rounded-md bg-slate-700"></div>{" "}
                <p>1 Jan 23 - 31 Dec 24 </p>
            </div>
            </div> */}
          <p onClick={generatePDF} className="cursor-pointer text-blue-900">
            <FiDownload />
          </p>
        </div>
      </div>

      <div className="  bg-newGradient dark:bg-lightGradient text-white rounded-lg p-4">
            {
              hotelPerformanceData?.data?.overallOutcome >50
              ?
              <h1 className="bg-[#0DA858] text-white w-fit px-3 rounded-lg font-bold">
                Congratulations, your hotel performed excellent!!
              </h1>
              :
              <h1 className="bg-[#d14747] text-white w-fit px-3 rounded-lg font-bold">
              We need to work on hotel, immediately
              </h1>
            }
        <div className="border-b-2 border-dotted mt-2 w-full"></div>
        <div className="flex justify-between">
          <div className="flex flex-col w-[65%]">
            <div className="flex mt-2 ">
              <div className="rounded-full border h-16 w-16">
                {" "}
                <img src={RevensHey} className="object-cover" alt="" />
              </div>

              <div className="card ">
                <div className="flex gap-4 items-center  mt-2 ml-2">
                  <h1 className="font-bold">Revens</h1>{" "}
                  {/* <p className="text-xs">26 minutes ago</p> */}
                </div>
                <p className="ml-2">
                  "Hotel’s overall performance score is{" "}
                  {hotelPerformanceData?.data?.overallOutcome} %"
                </p>
              </div>
            </div>

            <p className="m-2 w-full  m">
              {hotelPerformanceData?.data?.outcomes}
            </p>
          </div>
          <div className=" flex-strech w-[30%] flex items-center justify-center text-white">
            <RadialProgress
              size={130}
              strokeWidth={15}
              percentage={hotelPerformanceData?.data?.overallOutcome}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-white/70 flex h-14  justify-between rounded-lg items-center p-4 ">
        <div className="flex  w-[90%] h-2 gap-2 justify-center ">
          {hotelPerformanceData?.data?.ProgressBar.map((cocain) => (
            <div className="w-full h-full text-center">
              <a
                href={`#${cocain?.metricName.replace(" ", "-").toLowerCase()}`}
              >
                <div
                  data-tooltip-id={cocain?.metricName}
                  data-tooltip-content={cocain?.metricName}
                  className={`text-center w-full h-full ${cocain.value === 1 ? "bg-green-600" : cocain.value === -1 ? "bg-red-600" : "bg-yellow-300"} rounded-lg w-1/6`}
                >
                  <Tooltip
                    id={cocain?.metricName}
                    style={{
                      borderRadius: "10px",
                      maxWidth: "350px",
                      fontSize: "12px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflow: "hidden",
                    }}
                  />
                </div>
                <h1>{cocain?.metricName}</h1>
              </a>
            </div>
          ))}
        </div>
      </div>

      <HotelAnalysis
        imageFirst={true}
        image={
          <BarChartComponent
            name="Revenue"
            period1={hotelPerformanceData?.data?.Revenue?.period1}
            period2={hotelPerformanceData?.data?.Revenue?.period2}
            isLoading={hotelPerformanceIsLoading}
            graphData={hotelPerformanceData?.data?.Revenue?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            value={-1}
            tableData={hotelPerformanceData?.data?.Revenue?.graphData}
          />
        }
      >
        <div className=" ">
          <div className="flex flex-row items-center justify-start mb-4 gap-2">
            <h2 id={"revenue"} className="text-xl font-bold">
              Revenue
            </h2>

            <div
              className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-auto"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(hotelPerformanceData?.data?.Revenue?.period1, hotelPerformanceData?.data?.Revenue?.period2).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
            >
              <img
                src={
                  calculatePercentage(
                    hotelPerformanceData?.data?.Revenue?.period1,
                    hotelPerformanceData?.data?.Revenue?.period2
                  )
                    .toFixed(0)
                    .toString()
                    .includes("-")
                    ? down
                    : increase
                }
                width={isIpad && 13}
              />
            {    calculatePercentage(
                    hotelPerformanceData?.data?.Revenue?.period1,
                    hotelPerformanceData?.data?.Revenue?.period2
                  ).toFixed(2)}
            </div>
          </div>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal text-lg ml-5 text-lg space-y-2 ">
              {hotelPerformanceData?.data?.Revenue?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={false}
        name={"Ranks"}
        image={
          <BarChartComponent
            name="Ranks"
            singleValue={hotelPerformanceData?.data?.Ranks?.heroRanking}
            singleSecondValue={
              hotelPerformanceData?.data?.Ranks?.compsetAvgRank
            }
            isLoading={hotelPerformanceIsLoading}
            graphData={hotelPerformanceData?.data?.Ranks?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Ranks?.graphData}
          />
        }
        value={-1}
      >
        <div>
          <div className="flex flex-row items-center justify-start mb-4 gap-2">
          <h2 id={"rank"} className="text-xl font-bold ">
            Rank
          </h2>

            <div
              className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-[60px]"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(hotelPerformanceData?.data?.Ranks?.heroRanking, hotelPerformanceData?.data?.Revenue?.compsetAvgRank).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
            >
              <img
                src={
                  calculatePercentage(
                    hotelPerformanceData?.data?.Ranks?.heroRanking,
                    hotelPerformanceData?.data?.Ranks?.compsetAvgRank
                  )
                    .toFixed(0)
                    .toString()
                    .includes("-")
                    ? down
                    : increase
                }
                width={isIpad && 13}
              />
              {  calculatePercentage(
                    hotelPerformanceData?.data?.Ranks?.heroRanking,
                    hotelPerformanceData?.data?.Ranks?.compsetAvgRank
                  ).toFixed(2)}
            </div>
          </div>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal text-lg ml-5 space-y-2 ">
              {hotelPerformanceData?.data?.Ranks?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={true}
        image={
          <BarChartComponent
            isLoading={hotelPerformanceIsLoading}
            name="Source"
            graphData={hotelPerformanceData?.data?.Source?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Source?.graphData}
          />
        }
        value={0}
      >
        <div>
          <div className="flex flex-row items-center justify-start mb-4 gap-2">
          <h2 id={"source"} className="text-xl font-bold ">
            Source
          </h2>

            {/* <div
              className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-[60px]"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(hotelPerformanceData?.data?.Ranks?.heroRanking, hotelPerformanceData?.data?.Revenue?.compsetAvgRank).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
            >
              <img
                src={
                  calculatePercentage(
                    hotelPerformanceData?.data?.Ranks?.heroRanking,
                    hotelPerformanceData?.data?.Revenue?.compsetAvgRank
                  )
                    .toFixed(0)
                    .toString()
                    .includes("-")
                    ? down
                    : increase
                }
                width={isIpad && 13}
              />
            </div> */}
          </div>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal ml-5 text-lg space-y-2  ">
              {hotelPerformanceData?.data?.Source?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={false}
        image={
          <BarChartComponent
            name="Cancellations"
            period1={hotelPerformanceData?.data?.Cancellations?.period1}
            period2={hotelPerformanceData?.data?.Cancellations?.period2}
            graphData={hotelPerformanceData?.data?.Cancellations?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Cancellations?.graphData}
          />
        }
        value={1}
      >
        <div id="cancellations">
          <div className="flex flex-row items-center justify-start mb-4 gap-2">
          <h2 className="text-xl font-bold ">Cancellation analysis</h2>

            <div
              className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-[60px]"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(hotelPerformanceData?.data?.Cancellations?.period1, hotelPerformanceData?.data?.Cancellations?.period2).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
            >
              <img
                src={
                  calculatePercentage(
                    hotelPerformanceData?.data?.Cancellations?.period1,
                    hotelPerformanceData?.data?.Cancellations?.period2
                  )
                    .toFixed(0)
                    .toString()
                    .includes("-")
                    ? down
                    : increase
                }
                width={isIpad && 13}
              />
            </div>
          </div>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal ml-5 text-lg space-y-2 ">
              {hotelPerformanceData?.data?.Cancellations?.outcomes.map(
                (item) => (
                  <li>{item}</li>
                )
              )}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={false}
        image={
          <BarChartComponent
            name="Competitor"
            singleValue={hotelPerformanceData?.data?.Competitor?.heroBaseRate}
            singleSecondValue={
              hotelPerformanceData?.data?.Competitor?.compsetBaseRate
            }
            graphData={hotelPerformanceData?.data?.Competitor?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Competitor?.graphData}
          />
        }
        value={-1}
      >
        <div>
          <h2 id="competitor" className="text-xl font-bold mb-4">
            Competitor Pricing
          </h2>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal  text-lg  ">
              {hotelPerformanceData?.data?.Competitor?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={true}
        image={
          <BarChartComponent
            name="Room"
            period1={hotelPerformanceData?.data?.Room?.period1}
            period2={hotelPerformanceData?.data?.Room?.period2}
            isLoading={hotelPerformanceIsLoading}
            graphData={hotelPerformanceData?.data?.Room?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Room?.graphData}
          />
        }
        value={-1}
      >
        <div>
          <h2 id="room-nights" className="text-xl font-bold mb-4">
            Room
          </h2>
          <div className="max-h-[500px] overflow-y-auto   overflow-x-visible">
            <ol className="list-decimal  ml-5 space-y-2  text-lg ">
              {hotelPerformanceData?.data?.Room?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={false}
        image={
          <BarChartComponent
            name="Lead"
            period1={hotelPerformanceData?.data?.Lead?.period1}
            period2={hotelPerformanceData?.data?.Lead?.period2}
            graphData={hotelPerformanceData?.data?.Lead?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Lead?.graphData}
          />
        }
        value={-1}
      >
        <div>
          <div className="flex flex-row items-center justify-start mb-4 gap-2">
          <h2 id="lead-time" className="text-xl font-bold ">
            Lead
          </h2>

            {/* <div
              className={`${isIpad ? "text-[8px] w-[52px] " : "text-xs w-[60px]"} md:text-[10px] mt-1   py-1 rounded-lg md:px-2 justify-start md:justify-between ${calculatePercentage(hotelPerformanceData?.data?.Lead?.period1, hotelPerformanceData?.data?.Lead?.period2).toFixed(0).toString().includes("-") ? "bg-[#ef8f8f] text-[#cc2929]" : "bg-[#3dd598] text-[#0f6b42]"}  flex gap-2 items-center`}
            >
              <img
                src={
                  calculatePercentage(
                    hotelPerformanceData?.data?.Lead?.period1,
                    hotelPerformanceData?.data?.Lead?.period2
                  )
                    .toFixed(0)
                    .toString()
                    .includes("-")
                    ? down
                    : increase
                }
                width={isIpad && 13}
              />
            </div> */}
          </div>
          <div className="max-h-[500px] overflow-y-auto ">
            <ol className="list-decimal  ml-5 text-lg space-y-2 t ">
              {hotelPerformanceData?.data?.Lead?.outcomes.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
        </div>
      </HotelAnalysis>

      <HotelAnalysis
        imageFirst={true}
        name={"Reputation"}
        image={
          <BarChartComponent
            name="Reputation"
            singleValue={hotelPerformanceData?.data?.Reputation?.heroRating}
            singleSecondValue={
              hotelPerformanceData?.data?.Reputation?.compsetAvgRating
            }
            isLoading={hotelPerformanceIsLoading}
            graphData={hotelPerformanceData?.data?.Reputation?.graphData}
          />
        }
        table={
          <HotelPerformanceTable
            tableData={hotelPerformanceData?.data?.Reputation?.graphData}
          />
        }
        value={1}
      >
        <div>
          <h2 id="reputation" className="text-xl font-bold mb-4">
            Reputation
          </h2>
          <ol className="list-decimal ml-5 text-lg space-y-2 ">
            {hotelPerformanceData?.data?.Reputation?.outcomes.map((item) => (
              <li>{item}</li>
            ))}
          </ol>
        </div>
      </HotelAnalysis>
    </div>
  );
};

export default HotelOverview;

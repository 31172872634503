import React, { useState } from "react";
import OtaCards from "../../components/otaCards";
import AreaChart from "../../components/charts/areaChart";
import RateFluctuationCard from "../../components/cards/rateFluctuationCard";
import ReviewCard from "../../components/cards/reviewCard";
import {
  useGetRatingLeaderboardQuery,
  useGetRatingOverviewQuery,
  useGetRatingRecentReviewQuery,
  useGetRatingTrendQuery,
  useGetReputationOverviewQuery,
  useGetReviewkeywordQuery,
} from "../../redux/slices/reputation";
import CustomSkeleton from "../../components/Skeleton";
import { format } from "date-fns";
import ReputationOverview from "../../components/reputationOverview";
import { CurrentProperty, UserId } from "../../utils/impVars";
import ExcelDownloadButton from "../../components/excelDownloadButton";
import Modal from "../../components/modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Table from "../../components/table";
import Calendar from "../../components/calendar";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import { useNavigate } from "react-router-dom";
import { useGetKeywordsReputationQuery } from "../../redux/slices/dashboard";
import NoDataFound from "../../components/noData";

export function removeKeysFromArray(arr, keysToRemove) {
  return arr.map((obj) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => {
      delete newObj[key];
    });
    return newObj;
  });
}

const Reputation = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [avgCount, setAvgCount] = useState();
  const [rateTrendDate, setRateTrendDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [OtaId, setOtaId] = React.useState();
  const [reputationTable, setReputationTable] = useState();

  const navigate = useNavigate();
  const { data: getRatingOverview } = useGetRatingOverviewQuery({
    hId: CurrentProperty(),
    userId: UserId(),
  });
  const {
    data: getReputationOverview,
    isLoading: getReputationOverviewIsLoading,
    isFetching: getReputationOverviewIsFetching,
  } = useGetReputationOverviewQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: OtaId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: ratingLeaderboard,
    isLoading: ratingLeaderboardIsLoading,
    isFetching: ratingLeaderboardIsFetching,
  } = useGetRatingLeaderboardQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: OtaId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [selectedTag, setSelectedTag] = useState("All Reviews");

  const {
    data: recentsReviews,
    isLoading: recentReviewsIsLoading,
    isFetching: recentReviewsIsFetching,
  } = useGetRatingRecentReviewQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: OtaId,
      noResponse: true,
      userKeywords: selectedTag,
      pageSize: 10,
      pageNumber: pageNumber,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: ratingTrend } = useGetRatingTrendQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      otaId: OtaId,
      startDate: format(new Date(rateTrendDate), "yyyy-MM-dd"),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: keywordsData } = useGetKeywordsReputationQuery({
    hId: CurrentProperty(),
  });

  React.useEffect(() => {
    if (getRatingOverview && getRatingOverview["data"]) {
      setOtaId(getRatingOverview["data"][0]?.otaId);
    }
  }, [getRatingOverview]);

  const rateTrendHotelNames =
    ratingTrend?.data &&
    ratingTrend?.data
      ?.filter((item) => item?.hotelName)
      .map((item) => {
        return {
          label: item?.hotelName,
          value: item?.hotelName,
        };
      });
  const [selectedHotel, setSelectedHotel] = useState(
    rateTrendHotelNames && rateTrendHotelNames[0]?.value
  );

  const rateTrendLabel =
    ratingTrend?.data &&
    ratingTrend?.data
      // ?.filter?.((ota) => {
      //   return ota?.hotelName === selectedHotel;
      // })
      .map((ota) => {
        return format(new Date(ota?.date), "dd-MMM-yy");
      });
  const rateTrendData =
    ratingTrend?.data &&
    ratingTrend?.data
      // ?.filter?.((ota) => {
      //   return ota?.hotelName === selectedHotel;
      // })
      .map((ota) => {
        return ota?.ratingScore;
      });
  const column = [
    {
      name: "Date",
      selector: (row) => format(new Date(row["date"]), "dd-MMM-yy"),
    },
    {
      name: "Rating Score",
      selector: (row) => row["ratingScore"],
    },
    // {
    //   name: "Ota Name",
    //   selector: (row) => row["otaName"],
    // },
    // {
    //   name: "Ota Id",
    //   selector: (row) => row["otaId"],
    // },
    // {
    //   name: "Hotel Name",
    //   selector: (row) => row["hotelName"],
    // },
  ];

  return (
    <div>
      {reputationTable && (
        <Modal>
          <div className="lg:w-[80%] md:w-[90%] w-[95%] h-[80%] scroll-container relative sm:pt-5 sm:pb-5 sm:pl-5 sm:pr-5 px-5 pt-5 pb-10 rounded-md bg-white">
            <div className="mb-2 text-lg font-medium flex justify-between">
              <p> {"Reputation Report"}</p>
              <p className="pr-6 filter invert">
                <ExcelDownloadButton
                  filename={"Reputation Report"}
                  data={ratingTrend?.data}
                  tooltip={true}
                />
              </p>
            </div>

            <div className="mt-5" onClick={(e) => e.stopPropagation()}>
              {
                <Table
                  columns={column}
                  data={ratingTrend?.data}
                  pagination={true}
                />
              }
            </div>

            <div
              className="absolute h-fit w-fit right-2 sm:right-4 sm:top-4 bottom-[6px] sm:bottom-0  font-semibold cursor-pointer hover:text-red-500 flex items-center"
              onClick={() => {
                setReputationTable(false);
              }}
            >
              <AiOutlineCloseCircle size={24} />
            </div>
          </div>
        </Modal>
      )}
      <div className="flex justify-between items-center w-[100%]">
        <div className="text-[17px] text-white dark:text-black flex items-center p-4 ">
          {/* <BackButton dashboard={true} />  */}
          Overview
          {getRatingOverview?.asPer && (
            <span className="text-[#eff0f2] dark:text-black text-[12px] ml-1 mt-1">
              as per{" "}
              {getRatingOverview?.asPer &&
                format(new Date(getRatingOverview?.asPer), "dd MMM yyyy")}
            </span>
          )}
        </div>
        <div className="p-4 flex gap-4">
          {/* <Dropdown selectedItem={"MTD"} /> */}
          {/* <div className="dark:bg-[#252C3B] bg-[#CCD6E5] cursor-pointer flex items-center h-[28] px-2 rounded-md">
            <img src={filterIcon} />
          </div> */}
        </div>
      </div>
      <div>
        <div className="flex overflow-scroll scrollHide gap-4 p-4">
          {getRatingOverview &&
            getRatingOverview["data"].map((ota, index) => {
              return (
                <OtaCards
                  index={index}
                  onClick={() => {
                    setSelectedIndex(index);
                    setOtaId(ota?.otaId);
                  }}
                  rank={ota.ratingScore}
                  title={ota.otaName}
                  selectedIndex={selectedIndex}
                  // data={data}
                  otaLogo={ota?.otaImage}
                  isCurrency={false}
                  totalCount={ota?.totalRatingCount}
                  totalCountState={true}
                  // setAvgCount={setAvgCount}
                  key={index}
                />
              );
            })}
        </div>
      </div>
      <div className="w-[100%] flex-col md:flex-row mt-4 flex gap-4 px-4">
        {!ratingLeaderboardIsLoading &&
        !getReputationOverviewIsLoading &&
        !ratingLeaderboardIsFetching &&
        !getReputationOverviewIsFetching ? (
          <div className="w-full md:w-[70%]">
            <div className="w-[100%] bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-xl">
              <div className="flex justify-between">
                <div className="text-[17px] text-white p-4 font-[500]">
                  Ratings Overview
                </div>
                <div className="p-4 flex gap-4">
                  <Calendar
                    selectedDate={rateTrendDate}
                    setSelectedDate={setRateTrendDate}
                  />
                  <div className="mr-2 flex items-center">
                    <InfoToolTip
                      content={"This chart offers a visual representation of the property's reputation across various review platforms and timeframes"}
                      index={"overview"}
                      position={"left"}
                    />
                  </div>
                </div>
              </div>
              <AreaChart
                xaxisShow={true}
                showSingleSeries={true}
                yaxisShow={true}
                currentSeriesData={rateTrendData}
                currentSeriesName={rateTrendLabel}
                height={292}
                downloadButtonData={
                  ratingTrend?.data &&
                  removeKeysFromArray(ratingTrend?.data, ["otaId", "hId"])
                }
                fileName={"Reputation Overview Report"}
                setTableState={() => {
                  setReputationTable(true);
                }}
                currentLabel={"Rating "}
                labelDivideValue={3}
                textWhite={true}
              />
            </div>
            {/* <div className="mt-4">
              <ReputationOverview
                data={getReputationOverview && getReputationOverview["data"]}
              />
            </div> */}
          </div>
        ) : (
          <div className="w-[70%]">
            <CustomSkeleton width={"100%"} height={400} />
          </div>
        )}
        {!ratingLeaderboardIsLoading &&
        !getReputationOverviewIsLoading &&
        !ratingLeaderboardIsFetching &&
        !getReputationOverviewIsFetching ? (
          <div className="w-full md:w-[30%] flex flex-col gap-4">
            <RateFluctuationCard
              showLogo={false}
              title={"Leaderboard"}
              data={ratingLeaderboard && ratingLeaderboard["data"]}
              isRating={true}
              showDropDown={false}
              tooltipText={
                "This leaderboard provides a comparative analysis of the property's reputation against competitors within the market"
              }
              height={"418px"}
            />
            {/* 
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div className="text-[16px] font-[500] my-2">
                  Recent Reviews
                </div>
              
                <div  className="cursor-pointer" onClick={()=>navigate('/rate-pulse/reputation/reviews',{state:{reviews:recentsReviews["data"]?.reviews,recentReviews:recentsReviews, totalRating:recentsReviews["data"]?.otaTotalRating,otaId:OtaId,selectedIndex:selectedIndex}})}> View All</div>
              </div>
              <div className="h-[500px] overflow-scroll scrollHide">
                {recentsReviews &&
                  recentsReviews["data"] &&
                  recentsReviews["data"]?.reviews?.slice(0,5).map((review) => {
                    
                    return (
                      <ReviewCard
                        otaLogo={
                          recentsReviews["data"] &&
                          recentsReviews["data"]?.otaLogo
                        }
                        otaColor={
                          recentsReviews["data"] &&
                          recentsReviews["data"]?.otaColor
                        }
                        name={review?.guestName}
                        description={review?.reviewText}
                        rating={review?.rating}
                        time={format(
                          new Date(review?.publishDate),
                          "dd-MMM-yy"
                        )}
                        totalRating={recentsReviews["data"]?.otaTotalRating}
                      />
                    );
                  })}
              </div>
            </div> */}
          </div>
        ) : (
          <div className="w-[30%]">
            <CustomSkeleton width={"100%"} height={400} />
          </div>
        )}
      </div>
      <div className="mt-4">
        {!recentReviewsIsFetching && !recentReviewsIsLoading ? (
          <NewReviewsComponent
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            avgCount={avgCount}
            keywordsData={keywordsData}
            reputationData={
              getReputationOverview && getReputationOverview?.data
            }
            recentsReviews={recentsReviews}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        ) : (
          <div className="w-[100%] mx-4">
            <CustomSkeleton height={300} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reputation;

const NewReviewsComponent = ({
  recentsReviews,
  reputationData,
  keywordsData,
  setSelectedTag,
  selectedTag,
  avgCount,
  setPageNumber,
  pageNumber,
}) => {
  function cleanAndMapData(data) {
    const { sentimentData, otaId, otaName, reputationData, ...ratings } = data;

    const ratingArray = Object.keys(ratings).map((key) => {
      return {
        ratingType: key,
        ratingValue: ratings[key],
      };
    });

    return ratingArray;
  }
  const [showSearch, setShowSearch] = useState(false);
  const [showRepliesIndex, setShowRepliesIndex] = useState();
  // const keywords = ["All Reviews",...keywordsData?.data?.map((item)=>{
  //   return item?.keyword
  // })]
  const { data: allKeywords } = useGetReviewkeywordQuery({
    hId: CurrentProperty(),
  });
  const keywords = allKeywords?.data?.map((item) => item?.keyword);
  const updatedKeywords = keywords && [...["All Reviews"], ...keywords];
  return (
    <div className="mx-4 p-4 flex gap-8  mt-4 rounded-lg bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md">
      <div className="w-[25%]">
        <div className="text-[17px] text-white">User Ratings and Reviews</div>
        <div className="flex mt-4 items-center gap-2">
          <div className="p-4 w-fit rounded-lg bg-[#2d39e7] text-white font-semibold">
            {/* {avgCount} */} {reputationData?.reputationData?.ratingCount}
          </div>
          <div>
            <div className="text-lg text-white">Very Good</div>
            <div className="text-[#ffffff] text-sm font-thin">
              {reputationData?.reputationData?.totalRatings || "--"} Ratings,{" "}
              {reputationData?.reputationData?.totalReviews} Reviews
            </div>
          </div>
        </div>
        <div className="mt-2">
          {reputationData &&
            cleanAndMapData(reputationData)?.map((item) => {
              return (
                <div className="flex justify-between items-center gap-4">
                  <div className="font-thin w-[80px] text-white max-w-[80px] truncate">
                    {" "}
                    {item?.ratingType?.split("total")[1]?.split("Rating")} Star
                  </div>

                  <ProgressBar
                    percentage={item?.ratingValue}
                    maxWidth="150px"
                  />
                  <div className="font-thin w-[20px] text-white">{item?.ratingValue}%</div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="w-[75%]">
        <div className="flex justify-between">
          <div className="text-[#ffffff] text-[17px]">Filter By:</div>
        </div>
        <div className="flex gap-2 justify-between items-center mt-4">
          <div className="flex gap-2">
            {updatedKeywords &&
              updatedKeywords?.map((item) => {
                return (
                  <div
                    onClick={() => setSelectedTag(item)}
                    className={`px-2 cursor-pointer hover:border hover:border-[#8B9DE5] hover:bg-[#3150BA33] hover:text-[#003099] transition-all ease-in-out duration-200 py-1 w-fit text-sm rounded-lg ${selectedTag === item ? " border-[#8B9DE5] bg-[#e6e8ed33] text-[#ffffff]" : " bg-[#FFFFFF]  border-[#2D354670] text-[#2D354678]"}`}
                  >
                    {item}
                  </div>
                );
              })}
          </div>
          <div className="flex items-center gap-2">
  {/* Previous button */}
  {pageNumber > 1 && (
    <button
      className="px-2 rounded-lg py-1 bg-gray-600 text-white text-sm hover:bg-blue-600 transition"
      onClick={() => setPageNumber(pageNumber - 1)}
    >
      Prev
    </button>
  )}

  {/* First page */}
  {pageNumber > 2 && (
    <>
      <button
        className={`px-2 rounded-lg py-1 ${
          pageNumber === 1 ? "bg-blue-600 text-white" : "bg-gray-600 text-white hover:bg-blue-600"
        } transition text-sm`}
        onClick={() => setPageNumber(1)}
      >
        1
      </button>
      {pageNumber > 3 && <span className="px-2 text-sm">...</span>}
    </>
  )}

  {/* Previous page */}
  {pageNumber > 1 && (
    <button
      className="px-2 rounded-lg py-1 bg-gray-600 text-sm text-white hover:bg-blue-600 transition"
      onClick={() => setPageNumber(pageNumber - 1)}
    >
      {pageNumber - 1}
    </button>
  )}

  {/* Current page */}
  <button
    className="px-2 rounded-lg py-1 bg-blue-600 text-sm text-white transition"
    onClick={() => setPageNumber(pageNumber)}
  >
    {pageNumber}
  </button>

  {/* Next page */}
  {pageNumber < recentsReviews?.data?.totalPages && (
    <button
      className="px-2 rounded-lg py-1 bg-gray-600 text-white hover:bg-blue-600 text-sm transition"
      onClick={() => setPageNumber(pageNumber + 1)}
    >
      {pageNumber + 1}
    </button>
  )}

  {/* Last page */}
  {pageNumber < recentsReviews?.data?.totalPages-1 && 2!= recentsReviews?.data?.totalPages && (
    <>
      {pageNumber < 8 && <span className="px-2">...</span>}
      <button
        className={`px-2 rounded-lg py-1 ${
          pageNumber === 10 ? "bg-blue-600 text-white" : "bg-gray-600 text-white hover:bg-blue-600"
        } transition text-sm`}
        onClick={() => setPageNumber(recentsReviews?.data?.totalPages)}
      >
        {recentsReviews?.data?.totalPages}
      </button>
    </>
  )}

  {/* Next button */}
  {pageNumber < recentsReviews?.data?.totalPages && (
    <button
      className="px-2 rounded-lg py-1 bg-gray-600 text-white text-sm hover:bg-blue-600 transition"
      onClick={() => setPageNumber(pageNumber + 1)}
    >
      Next
    </button>
  )}
</div>

        </div>
        {recentsReviews["data"]?.reviews?.length > 0 ? (
          <div className="grid h-[600px] overflow-scroll mt-4 grid-cols-1 gap-4">
            {recentsReviews &&
              recentsReviews["data"] &&
              recentsReviews["data"]?.reviews
                ?.slice(0, 5)
                .map((review, index) => {
                  return (
                    <ReviewCard
                      otaLogo={
                        recentsReviews["data"] &&
                        recentsReviews["data"]?.otaLogo
                      }
                      otaColor={
                        recentsReviews["data"] &&
                        recentsReviews["data"]?.otaColor
                      }
                      name={review?.guestName}
                      description={review?.reviewText}
                      rating={review?.rating}
                      time={format(new Date(review?.publishDate), "dd-MMM-yy")}
                      totalRating={recentsReviews["data"]?.otaTotalRating}
                      responses={review?.responses}
                      index={index}
                      onClick={
                        index === showRepliesIndex
                          ? () => setShowRepliesIndex()
                          : () => setShowRepliesIndex(index)
                      }
                      showRepliesIndex={showRepliesIndex}
                      keywords={review?.sentimentData?.zc}
                    />
                  );
                })}
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

const ProgressBar = ({ percentage = 0, maxWidth = "100%" }) => {
  const containerStyle = {
    width: "100%",
    height: "8px",
    borderRadius: "5px",
    overflow: "hidden",
    backgroundColor: "#D9D9D9",
    maxWidth: maxWidth,
    position: "relative",
  };

  const progressStyle = {
    width: `${percentage}%`,
    height: "100%",
    // backgroundColor: "#272A3D",
    borderRadius: "5px",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle} >
      <div style={progressStyle} className="bg-[#272A3D] dark:bg-blue-700"></div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import NewOtaOptions from "../../../utils/newOTAOptions";
import { AiFillCloseCircle, AiOutlineDown } from "react-icons/ai";

export const MultiSelectNestedDropdown = ({
  placeHolder,
  selectedItem = [],
  setSelectedItem,
  checkBox,
  width,
  left,
  type = "",
}) => {
  const [showOption, setShowOption] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const ref = useRef();
  let typo = "seggregatedSource";
  const options = NewOtaOptions();
  const selectedLabels =
    Array?.isArray(selectedItem) && selectedItem?.map((item) => item?.label);

  const handleAdd = (option) => {
    if (option.label === "Select All") {
      if (!selectAllChecked) {
        setSelectedItem(options);
      } else {
        setSelectedItem([]);
      }
      setSelectAllChecked(!selectAllChecked);
    } else if (option.type==="parent"){
        setSelectedItem([...selectedItem,...option?.children])
    } else {
      if (selectedLabels?.includes(option.label)) {
        const updatedSelection = selectedItem.filter(
          (item) => item.label !== option.label
        );
        setSelectedItem(updatedSelection);
      } else {
        setSelectedItem([...selectedItem, option]);
      }
    }
  };

  const handleDelete = (toDeleteName) => {
    const updatedSelection = selectedItem?.filter(
      (item) => item.label !== toDeleteName
    );
    setSelectedItem(updatedSelection);
  };

  useEffect(() => {
    if (
      options?.length === selectedItem?.length &&
      options?.length > 0 &&
      !selectAllChecked
    ) {
      setSelectAllChecked(true);
    }
    console.log(options, "options");
  }, [options, selectedItem, selectAllChecked]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setShowOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div className="relative" ref={ref}>
        <div
          className={` flex justify-between gap-4 items-center border-[#F0F0F0] bg-white/30 dark:bg-customSubDarkBg dark:text-white text-sm text-[#ffffff] ${selectedItem.length ? "px-2 py-1" : "p-2"}  rounded-lg cursor-pointer ${width ? width : "w-[300px]"}`}
          onClick={() => setShowOption(!showOption)}
        >
          {Array.isArray(selectedItem) &&
            placeHolder &&
            selectedItem.length === 0 &&
            placeHolder}
          {Array.isArray(selectedItem) &&
            !placeHolder &&
            selectedItem.length === 0 &&
            "Select Source"}

          <div className="flex gap-4">
            {Array.isArray(selectedItem) && selectedItem.length > 0 && (
              <div className="bg-white text-sm px-2 py-1 text-black rounded-lg flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <div className="truncate max-w-[80px]">
                    {Array.isArray(selectedItem) && selectedItem[0]?.label}
                  </div>
                </div>
                <div
                  className="text-gray-500"
                  onClick={() => {
                    handleDelete(selectedItem[0]?.label);
                  }}
                >
                  X
                </div>
              </div>
            )}
            {Array.isArray(selectedItem) && selectedItem.length > 1 && (
              <div className="flex items-center gap-2 text-sm text-blue-500  bg-white px-2 py-1 rounded-lg whitespace-nowrap">
                <div>{selectedItem.length} +</div>
                <div onClick={() => setSelectedItem([])}>
                  <AiFillCloseCircle />
                </div>
              </div>
            )}
          </div>

          <div className="relative right-0">
            <AiOutlineDown
              className={`${showOption ? "rotate-180" : ""} transition-all ease-in-out duration-150`}
            />
          </div>
        </div>
        {showOption && (
          <div
            className="absolute bg-gray-50 w-[300px] rounded-lg mt-2 text-mainLight dark:bg-gray-500 100  z-50"
            style={{ left: `${left}` }}
          >
            <div
              className="w-[100%] py-1 px-2 text-sm text-blue-500 cursor-default text-end "
              onClick={() => {
                setSelectedItem([]);
                setShowOption(false);
              }}
            >
              Clear All
            </div>
            <div className="h-[180px] overflow-y-scroll scrollHide border rounded-lg">
              <div
                key="select-all"
                className="p-2 text-sm flex sticky top-0 bg-white justify-start items-center gap-2 text-[#5b5e65] hover:bg-gray-50 cursor-pointer"
              >
                <input
                  className=""
                  type="checkbox"
                  onClick={() => {
                    handleAdd({ label: "Select All" });
                    setShowOption(false);
                  }}
                  checked={selectAllChecked}
                  id="selectAll"
                />
                <label className="cursor-pointer" htmlFor="selectAll">
                  Select All
                </label>
              </div>
              {options?.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="text-sm dark:text-mainDark flex text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b gap-2"
                  >
                    {option?.type === "parent" ? (
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2">
                          <input
                            className=""
                            type="checkbox"
                            onClick={() => handleAdd(option)}
                            id={option?.label}
                            checked={
                              selectedLabels &&
                              selectedLabels?.includes(option.label)
                            }
                          />
                          <label
                            className="cursor-pointer"
                            htmlFor={option?.label}
                          >
                            {option?.label}
                          </label>
                        </div>
                        <div className="flex flex-col gap-1">
                          {option?.children?.map((child, childId) => {
                            return (
                              <div className="flex flex-row gap-2 pl-4">
                                <input
                                  className=""
                                  type="checkbox"
                                  onClick={() => handleAdd(child)}
                                  id={option?.label}
                                  checked={
                                    selectedLabels &&
                                    selectedLabels?.includes(child.label)
                                  }
                                />
                                <label
                                  className="cursor-pointer"
                                  htmlFor={child?.label}
                                >
                                  {child?.label}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <>
                        <input
                          className=""
                          type="checkbox"
                          onClick={() => handleAdd(option)}
                          id={option?.label}
                          checked={
                            selectedLabels &&
                            selectedLabels?.includes(option.label)
                          }
                        />
                        <label
                          className="cursor-pointer"
                          htmlFor={option?.label}
                        >
                          {option?.label}
                        </label>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

import React from "react";
import Dropdown from "../../../../../components/dropdown";
import Table from "../../../../../components/table";

const CMRoomMappin = ({
  roomOptions,
  selectedRoomOption,
  setSelectedRoomOption,
  selectedRoomPrice,
  setSelectedRoomPrice,
  handleAddData,
  roomMapColumns,
  ruleMappingData,
  applyRule,
  handleSAveData
}) => {
  return (
    <>
      <div className="mt-8">
        <div className="text-xs mb-2 text-lightFontColor ">
          Select Master Room & its adjustments:
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="bg-black/40 rounded-lg w-fit">
              <Dropdown
                options={roomOptions && roomOptions}
                right={-100}
                selectedItem={ruleMappingData?.masterRoom?.id?.label}
                setSelectedItems={setSelectedRoomOption}
              />
            </div>
            <div className="bg-black/25 rounded-lg w-fit py-0.5">
              <input
                placeholder="Enter rate"
                type="number"
                value={selectedRoomPrice}
                min={0}
                onChange={(e) => setSelectedRoomPrice(e.target.value)}
                className="text-sm focus:outline-none  bg-transparent text-white placeholder:text-white px-2"
              />
            </div>
          </div>
          <div className="text-lightFontColor flex items-center gap-4">
            <div className="] cursor-pointer text-sm" onClick={handleAddData}>
              Add More
            </div>
            <div className=" cursor-pointer text-sm">Adjust Rate Plan</div>
            {/* <div onClick={handleSAveData} className=" cursor-pointer bg-blue-100 text-blue-500 px-2 py-1 rounded-lg text-sm">Save</div> */}
          </div>
        </div>
      </div>
      <div className="h-[400px] scroll-container">
        <Table columns={roomMapColumns} data={ruleMappingData?.rooms} />
        {/* <CustomCMMappingTable
          // data={ruleMappingData}
          /> */}
      </div>
    </>
  );
};

export default CMRoomMappin;

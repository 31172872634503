import React, { useEffect } from "react";
import { useGetTemplateReportQuery } from "../../redux/slices/reports";
import { CurrentProperty, UserId } from "../../utils/impVars";
import { useNavigate } from "react-router-dom";
import BackdropLoader from "../../components/newLoader";
import { BackButton } from "../rateSuggestion/rateAnalysis";
import { Tooltip } from "react-tooltip";
import { useIsIpad } from "../../utils/isIpad";
import InfoToolTip from "../../components/tooltip/InfoToolTip";
import { FaRegHandPointer } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";
const TemplateReport = () => {
  const { data: TemplateReportData, isLoading } = useGetTemplateReportQuery({
    userId: UserId(),
    hId: CurrentProperty(),
  });
  const navigate = useNavigate();
  const redirect = (reportId) => {
    navigate(`${reportId}`);
  };
  const isIpad = useIsIpad();

  //   useEffect(() => {
  //     // Rebuild tooltips after the component is rendered
  //     Tooltip.rebuild();
  //   }, [TemplateReportData]);
  return (
    <div className=" p-5 w-full ">
      <div className="flex flex-col gap-2">
        <div className="flex items-center  text-white dark:text-black justify-start gap-2">
          {/* <BackButton className='flex-1'  mainDashboard={true} /> */}
          Report Templates
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 ml-6">
          <BackdropLoader loading={isLoading} />
          {TemplateReportData?.data?.map((item, index) => (
            <div
              key={item.reportId}
              id={item.reportId}
              className="p-4 border-2 dark:border-0 group rounded-lg relative z-0 cursor-pointer bg-newGradient text-white dark:text-white dark:bg-lightGradient drop-shadow-lg backdrop-blur-md hover:pl-5"
            >
              <div className="flex items-center justify-between">
                {" "}
                <h1
                  onClick={() => {
                    redirect(item.reportId);
                  }}
                  className="mb-2 flex  justify-between underline items-center gap-2 cursor-pointer  text-white dark:text-white"
                >
                  {/* <FaRegHandPointer className="hidden group-hover:block duration-200 ease-in-out transition-all" />{" "} */}
                  {/* <AiOutlineLink /> */}
                  {item.reportName}
                  {/* <span className="hidden text-xs group-hover:block duration-200 ease-in-out transition-all">
                    Click here
                  </span> */}
                </h1>
                {/* <InfoToolTip index={item.reportName} content={item?.reportDescription} /> */}
              </div>

              <p
                className="text-sm text-white dark:text-white  truncate min-w-[250px]"
                id="title"
              >
                <div
                  className={`truncate   ${isIpad ? "w-[180px]" : "w-[250px] "}`}
                  data-tooltip-id={item.reportId}
                  data-tooltip-content={item?.reportDescription}
                  // onClick={() => {
                  //   redirect(item.reportId);
                  // }}
                >
                  {" "}
                  {item?.reportDescription}
                </div>
                <Tooltip
                  id={item.reportId}
                  className="absolute z-50"
                  style={{
                    borderRadius: "10px",
                    maxWidth: "350px",
                    fontSize: "12px",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflow: "hidden",
                  }}
                  place="top" // This controls the direction of the tooltip
                />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplateReport;

import { useEffect, useState } from "react";
import { CurrentProperty } from "./impVars";

const NewOtaOptions = () => {
  const [ota, setOta] = useState([]);
  const gId = CurrentProperty();
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await fetch(
            `https://rxserver.retvenslabs.com/api/utils/getSourcesSegregatedData?hId=${gId}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const otaData = await response.json();
          let allSources=[]
          if (otaData && Array.isArray(otaData.data.sources)) {
            otaData?.data?.sources?.map((sourceSeg, sourceSegId)=>{
                let childItems = []
                sourceSeg?.sourceData?.sources.map((source,sourceid)=>{
                    childItems.push({type:'child',value:source,label:source})
                })
                let parentItem = {type:'parent',value:sourceSeg?.sourceData?.sourceSegment,label:sourceSeg?.sourceData?.sourceSegment, children:childItems}
                allSources=[...allSources, parentItem]
            })
            setOta(allSources);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return ota;
};

export default NewOtaOptions;

export const ActiveOtaOptions = () => {
  const [ota, setOta] = useState([]);

  useEffect(() => {
    const localOtas = JSON.parse(localStorage.getItem("activeOta"));
    if (localOtas) {
      const arr = localOtas.map((ota) => ({
        label: ota.otaName,
        value: ota.otaId,
      }));
      // Add the "All" option to the beginning of the array
      setOta([{ label: "All", value: "All" }, ...arr]);
    }
  }, []);

  return ota;
};

import React, { useEffect, useRef, useState } from "react";
import SettingButton from "../../../components/settingButton/SettingButton";
import SettingProfile from "../../../components/profile/SettingProfile";
import TableButton from "../../../components/settingButton/TableButton";
import ChannelCard from "../channelcard";
import profile from "../../../assets/img/8.svg";
import InputField from "../../../components/inputFeild";
import { FormProvider, useForm } from "react-hook-form";
import {
  useEditUserMutation,
  useGetUserDetailsQuery,
} from "../../../redux/slices/settings/accountSecurity";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CurrentProperty, UserId } from "../../../utils/impVars";
import BackdropLoader from "../../../components/newLoader";
import { ChannelManageRoomTypes } from "../../../redux/slices/settings/channelManager/roomTypes";
import { AiOutlineDown } from "react-icons/ai";
import { useChannelManagerListQuery } from "../../../redux/slices/onBoarding";
import { useAddNewPropertyChannelManagerMutation, useUpdateCMPasswordMutation } from "../../../redux/slices/settings/myProperties";
const AccountAndSecurity = () => {
  const {
    data: userDetails,
    isLoading: userDetailIsLoading,
    refetch,
  } = useGetUserDetailsQuery(
    {
      userId: UserId(),
    },
    { refetchOnMountOrArgChange: true }
  );

  const [twoFA_isLoading, setTwoFA_isLoading] = useState(false);
  const [twoFA, setTwoFA] = useState(false);
  const [editUser] = useEditUserMutation();
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [channelManagerPassowrd, setChannelManagerPassword] = useState(false);
  const storedImage = localStorage.getItem("profileImage");
  const [isLoading, setIsLoading] = useState(false);
  const handleCardClick = () => {
    setIsPasswordOpen(true);
  };
  const handleChannelManagerPassword = () => {
    setChannelManagerPassword(true);
  };
  const OpenEditProfile = () => {
    setIsEditProfileOpen(true);
  };

  const userId = UserId();

  const handleTwoFA = () => {
    setTwoFA_isLoading(true);
    setTwoFA(!twoFA);
    const formData = new FormData();
    formData.append("updateTwoFactorAuthentication", twoFA ? false : true);

    editUser({ formData, userId })
      .unwrap()
      .then((res) => {
        // Handle successful response
        toast.success(res.message, { position: "bottom-right" });
        setTwoFA_isLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Failed to edit user:", error);
      });
  };

  useEffect(() => {
    if (userDetails && userDetails?.data) {
      setTwoFA(userDetails?.data[0]?.twoFactorAuthentication);
    }
  }, [userDetails]);
  useEffect(() => {
    if (twoFA_isLoading || userDetailIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  });

  return (
    <>
      <BackdropLoader loading={isLoading} />
      {isEditProfileOpen ? (
        <EditUserProfile
          onClose={() => setIsEditProfileOpen(false)}
          data={userDetails?.data}
          refetch={refetch}
        />
      ) : isPasswordOpen ? (
        <ChangePassword onClose={() => setIsPasswordOpen(false)} />
      ) : channelManagerPassowrd ? (
        <ChannelManagerChangePassword
          onClose={() => setChannelManagerPassword(false)}
        />
      ) : (
        <div className="flex flex-col gap-4 w-[100%] h-fit">
          <SettingButton Title={"Account & Security"} hidebackbutton={true} />
          <div className="flex flex-col gap-4 w-[100%] rounded-xl">
            <div>
              {!isLoading && userDetails && (
                <SettingProfile
                  data={userDetails?.data}
                  onClick={OpenEditProfile}
                  profile={storedImage}
                />
              )}
            </div>
            {/* <div className="w-[100%] bg-[#F6F8FB] p-4 flex gap-2 flex-col rounded-2xl h-[252px]">
              <div className="w-[100%] md:w-[100%] lg:w-[50%]">
                <SettingProgressBar days={15} />
              </div>
              <p className="flex items-center w-[100%] md:w-[100%] lg:w-[50%] bg-[#D2DBF7] rounded-lg h-[34px] p-2 text-[14px] font-[400] text-[#131926]">
                11 day remains until your subscription plan requires action.
              </p>
              <div className="flex flex-col gap-2">
                <p className="text-[17px] text-[#0D1321] font-[500]">
                  Current Plan
                </p>
                <p className="text-[17px] text-[#0D1321] font-[600]">
                  Monthly Revenue Solution
                </p>
                <p className="text-[14px] text-[#0D1321] font-[500]">
                  Active until 22 March, 2024
                </p>
              </div>
              <div className=" flex gap-2 w-[100%]">
                <TableButton title={"Upgrade Plan"} />
                <TableButton title={"Manage subscription"} />
              </div>
            </div> */}
            <div className="flex flex-col gap-2">
              <ChannelCard
                title={"2 Factor Authentication"}
                check={true}
                data={userDetails?.data}
                checked={twoFA}
                onChange={handleTwoFA}
              />
              <ChannelCard
                title={"Change Password"}
                onClick={handleCardClick}
              />
              <ChannelCard
                title={"Channel Manager Change Password"}
                onClick={handleChannelManagerPassword}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountAndSecurity;

const EditUserProfile = ({ onClose, data, refetch }) => {
  const [editUser] = useEditUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(() => {
    const storedImage = localStorage.getItem("profileImage");
    return data[0]?.profileImg ? data[0]?.profileImg : storedImage || profile;
  });
  const [uploadImage, setUploadImage] = useState(null);
  const imageInputRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("profileImage", imageSrc);
  }, [imageSrc]);

  useEffect(() => {
    if (data) {
      methods.setValue("fullName", data[0]?.name);
      methods.setValue("phoneNumber", data[0]?.phoneNumber);
      methods.setValue("designation", data[0]?.designation);
    }
  }, [data]);

  const handleReplaceImage = (e) => {
    const file = e.target.files[0];
    setUploadImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleRemoveImage = () => {
    setIsLoading(true);
    const userId = window.localStorage.getItem("userId");
    setImageSrc(profile);
    const formData = new FormData();
    formData.append("removeImg", 0);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    editUser({ formData, userId })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right" });
        refetch();
        setIsLoading(false);
        // Handle successful response
        onClose();
      })
      .catch((error) => {
        toast.error(error.message, { position: "bottom-right" });
        // Handle error
        setIsLoading(false);
        console.error("Failed to edit user:", error);
      });
  };
  const methods = useForm({});

  const onSubmit = async (data) => {
    setIsLoading(true);
    const userId = window.localStorage.getItem("userId");
    const formData = new FormData();
    formData.append("image", uploadImage);
    formData.append("updatedphoneNumber", data?.phoneNumber);
    formData.append("updatedname", data?.fullName);
    formData.append("updateDesignation", data?.designation);
    // 'removeImg="0"'
    // 'updateDesignation="Collage Student"'
    // 'oldpassword="123"'
    // 'newpassword="123"'
    // 'confirmpassword="123"'
    editUser({ formData, userId })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { position: "bottom-right" });
        refetch();
        setIsLoading(false);
        // Handle successful response
        onClose();
      })
      .catch((error) => {
        toast.error(error.message, { position: "bottom-right" });
        // Handle error
        setIsLoading(false);
        console.error("Failed to edit user:", error);
      });
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              Title={"Edit User Profile"}
              onClick={methods.handleSubmit(onSubmit)}
              text={"Save"}
              onClose={onClose}
            />
            <div className="w-[100%] bg-newGradient text-lightFontColor dark:bg-lightGradient  p-4 flex gap-4 flex-col rounded-2xl h-[288px]">
              <div className="flex gap-4 items-center">
                <div className="h-[72px] w-[72px] rounded-lg overflow-hidden">
                  {imageSrc && (
                    <img
                      src={imageSrc}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <div className="flex gap-4">
                  <label htmlFor="imageInput">
                    <TableButton title={"Replace Image"} />
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={imageInputRef}
                      onChange={handleReplaceImage}
                    />
                  </label>
                  <TableButton
                    title={"Remove Image"}
                    cancel={true}
                    onClick={handleRemoveImage}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-[100%] md:w-[100%] lg:w-[40%]">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] font-[500]">Full Name</p>
                  <div className="rounded-lg overflow-hidden">
                    <InputField
                      type={"text"}
                      className={"text-gray-600 w-full bg-[#FBFCFD] h-9"}
                      label={false}
                      placeholder={"hotelemail@email.com"}
                      id={"fullName"}
                      name={"fullName"}
                      controls={methods.control}
                      rules={{ required: "Full Name is required" }}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <p className=" text-[14px] font-[500]">Designation</p>
                  <div className="rounded-lg overflow-hidden">
                    <InputField
                      type={"text"}
                      className={"text-gray-600 w-full bg-[#FBFCFD] h-9"}
                      label={false}
                      placeholder={"Manager"}
                      id={"designation"}
                      name={"designation"}
                      controls={methods.control}
                    />
                  </div>
                </div>
                {/* <div className='flex items-center justify-between'>
                  <p className='text-[#8794AD] text-[14px] font-[500]'>Email Address</p>
                  <div className="rounded-lg overflow-hidden">
                    <InputField
                      type={'email'}
                      className={"text-gray-600 w-full bg-[#FBFCFD] h-9"}
                      label={false}
                      placeholder={'hotelemail@email'}
                      id={'email'}
                      name={'email'}
                      controls={methods.control}

                    />
                  </div>
                </div> */}
                <div className="flex items-center justify-between">
                  <p className=" text-[14px] font-[500]">Phone Number</p>
                  <div className="rounded-lg overflow-hidden">
                    <InputField
                      type={"number"}
                      className={"text-gray-600 w-full bg-[#FBFCFD] h-9"}
                      label={false}
                      placeholder={"+91 6553432345"}
                      id={"phoneNumber"}
                      name={"phoneNumber"}
                      controls={methods.control}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const ChangePassword = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editUser] = useEditUserMutation();
  const [passwordIsLoading, setisPasswordIsLoading] = useState(false);
  const methods = useForm({});
  const selectedUserId = UserId();
  const onSubmit = async (data) => {
    setIsLoading(true);
    setisPasswordIsLoading(true);
    const userId = selectedUserId;
    const formData = new FormData();
    formData.append("oldpassword", data?.currentPassword);
    formData.append("newpassword", data?.newPassword);
    formData.append("confirmpassword", data?.confirmNewPassword);
    // 'removeImg="0"'
    // 'updateDesignation="Collage Student"'
    // 'oldpassword="123"'
    // 'newpassword="123"'
    // 'confirmpassword="123"'
    editUser({ formData, userId })
      .unwrap()
      .then((res) => {
        // Handle successful response
        toast.success(res.message, { position: "bottom-right" });
        setIsLoading(false);
        setisPasswordIsLoading(false);
        onClose();
      })
      .catch((error) => {
        // Handle error
        toast.error(error?.data?.message, { position: "bottom-right" });
        setIsLoading(false);
        console.error("Failed to edit user:", error);
      });
  };
  const passwordMatchValidator = (value) => {
    const newPassword = methods.getValues("newPassword");
    return value === newPassword || "Passwords do not match";
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              Title={"Change Password"}
              text={"Save"}
              onClick={methods.handleSubmit(onSubmit)}
              onClose={onClose}
            />
            <div className="w-[100%] bg-newGradient text-white dark:bg-lightGradient p-4 flex gap-4 flex-col rounded-2xl h-[257px]">
              <div className=" flex flex-col gap-4 w-[100%] md:w-[100%] lg:w-[50%]">
                <div className="flex flex-col gap-2">
                  <p className=" text-[14px] font-[500]">Current Password</p>
                  <div className="">
                    <InputField
                      type={"password"}
                      setting={true}
                      className={
                        "text-gray-600 w-full bg-[#FBFCFD] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Current Password"}
                      id={"currentPassword"}
                      name={"currentPassword"}
                      controls={methods.control}
                      rules={{ required: "Current Password Is Required" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <p className=" text-[14px] font-[500]">New Password</p>
                  <div className=" ">
                    <InputField
                      type={"password"}
                      setting={true}
                      className={
                        "text-gray-600 w-full bg-[#FBFCFD] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"New Password"}
                      id={"newPassword"}
                      name={"newPassword"}
                      controls={methods.control}
                      rules={{ required: "New Password Is Required" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <p className=" text-[14px] font-[500]">
                    Confirm New Password
                  </p>
                  <div className="">
                    <InputField
                      type={"password"}
                      setting={true}
                      className={
                        "text-gray-600 w-full bg-[#FBFCFD] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Confirm New Password"}
                      id={"confirmNewPassword"}
                      name={"confirmNewPassword"}
                      controls={methods.control}
                      rules={{
                        required: "Confirm Password Is Required",
                        validate: passwordMatchValidator,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const ChannelManagerChangePassword = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: channelManagerList } = useChannelManagerListQuery();
  const [propertyCode, setPropertyCode] = useState();
  const [updateCMPassword] =
  useUpdateCMPasswordMutation();
  const [passwordIsLoading, setisPasswordIsLoading] = useState(false);
  const [selectedCM, setSelectedCM] = useState();
  const [selectedCMId, setSelectedCMId] = useState();
  const [userName, setUserName] = useState();
  const hid = CurrentProperty();
  const userID = UserId();
  const methods = useForm({});
  const selectedUserId = UserId();
  const [showCMlist, setShowCMlist] = useState(false);
  // const onSubmit = async (data) => {
  //   setIsLoading(true);
  //   setisPasswordIsLoading(true);
  //   const userId = selectedUserId;
  //   const formData = new FormData();
  //   formData.append("oldpassword", data?.currentPassword);
  //   formData.append("newpassword", data?.newPassword);
  //   formData.append("confirmpassword", data?.confirmNewPassword);
  //   // 'removeImg="0"'
  //   // 'updateDesignation="Collage Student"'
  //   // 'oldpassword="123"'
  //   // 'newpassword="123"'
  //   // 'confirmpassword="123"'

  // };
  const onSubmit = (data) => {
    const channelManagerBody = {
      hId: hid,
      // newhId: status ? propertyData?.hId : newHId,
      cmId: selectedCMId,
      userId: userID,
      userCredential: {
        username: userName,
        password: data?.currentPassword,
        propertyCode: propertyCode,
      },
    };
    updateCMPassword(channelManagerBody)
      .unwrap()
      .then((res) => {
        toast.success("Channel Manager Credential Saved Successfully!", {
          position: "bottom-right",
        });
        // hideModal();
        onClose();
      })
      .catch(() => {
        toast.error("Something Went Wrong!", {
          position: "bottom-right",
        });
        // hideModal();
      });
  };
  const passwordMatchValidator = (value) => {
    const newPassword = methods.getValues("newPassword");
    return value === newPassword || "Passwords do not match";
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 w-[100%] h-[85vh]">
            <SettingButton
              Title={"Change Password"}
              text={"Save"}
              onClick={methods.handleSubmit(onSubmit)}
              onClose={onClose}
            />
            <div className="w-[100%] bg-newGradient text-white dark:bg-lightGradient p-4 flex gap-4 flex-col rounded-2xl ">
              <div className=" flex flex-col gap-4 w-[100%] md:w-[100%] lg:w-[50%]">
                <div>
                  <div className="text-[16px] my-2">Select Channel Manager</div>
                  <div
                    className={`border flex justify-between items-center ${showCMlist && "border-blue-400"} text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
                    onClick={() => setShowCMlist(!showCMlist)}
                  >
                    {selectedCM ? selectedCM : "Select Chanel Manager"}
                    {showCMlist && (
                      <div className="h-[200px] left-0 top-[30px] z-10 bg-white text-black mt-4 absolute w-[400px] shadow-xl drop-shadow-md rounded-md overflow-y-scroll">
                        {channelManagerList?.data ? (
                          channelManagerList?.data?.map((item) => {
                            return (
                              <div
                                onClick={() => {
                                  setSelectedCM(item?.cmname);
                                  setSelectedCMId(item?.cmid);
                                }}
                                className="text-sm cursor-pointer hover:bg-gray-100 duration-150 ease-in-out p-2"
                              >
                                {item?.cmname}
                              </div>
                            );
                          })
                        ) : (
                          <span className="p-4 mt-2">Loading...</span>
                        )}
                      </div>
                    )}

                    <div>
                      <AiOutlineDown
                        className={`${showCMlist ? "rotate-180" : "rotate-0"}  transition-all duration-200 ease-in-out`}
                      />
                    </div>
                  </div>
                </div>
                {selectedCM === "eZee" && (
                  <div>
                    <div className="text-[16px] my-2">Enter Property Code</div>
                    <input
                      placeholder="12345"
                      value={propertyCode}
                      onChange={(e) => setPropertyCode(e.target.value)}
                      className={` flex justify-between items-center text-black  text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
                    />
                  </div>
                )}
                <div>
                  <div className="text-[16px] my-2">Enter Username</div>
                  <input
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="XYZ12"
                    className={` flex justify-between items-center  text-black text-[16px] w-[400px] border cursor-pointer select-none p-2 text-sm rounded-lg relative`}
                  />
                </div>
                <div className="flex flex-col  w-[400px] gap-2">
                  <p className=" text-[14px] font-[500]">Enter Password</p>
                  <div className="">
                    <InputField
                      type={"password"}
                      setting={true}
                      className={
                        "text-black  w-full bg-[#FBFCFD] h-9 rounded-lg"
                      }
                      label={false}
                      placeholder={"Current Password"}
                      id={"currentPassword"}
                      name={"currentPassword"}
                      controls={methods.control}
                      rules={{ required: "Current Password Is Required" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import {
  AiFillCloseCircle,
  AiOutlineCaretUp,
  AiOutlineDown,
} from "react-icons/ai";
import formatCurrency from "../utils/formatCurrency";
import upIcon from "../assets/icons/increase.svg";
import down from "../assets/icons/decrease.svg";
import AreaChart from "../components/charts/areaChart";
import {
  useDashboardOverviewQuery,
  useGetExportSourceOverviewQuery,
  useGetNewDashboardOverviewQuery,
  useGetSourceOverviewQuery,
} from "../redux/slices/dashboard";
import { CurrentProperty, UserId } from "../utils/impVars";
import BackdropLoader from "../components/newLoader";
import TimeRange from "../components/timeRange";
import SemiDoughnutChart from "../components/charts/semiDougnutChart";
import OtaOptions from "../utils/otaOptions";
import { format } from "date-fns";
import NewTable from "./newTable/NewTable";
import InfoToolTip from "../components/tooltip/InfoToolTip";
import { useIsIpad } from "../utils/isIpad";
import CustomSkeleton from "../components/Skeleton";
import useIntelligenceDateRange from "../utils/dateRange";
import NoDataFound from "../components/noData";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { filterChartDataByName } from "../utils/filterChartDataByName";

function calculatePercentage(value, total) {
  // Check if the total value is not zero to avoid division by zero error
  if (total !== 0) {
    return ((value / total) * 100 - 100).toFixed(2) === "NaN" ||
      ((value / total) * 100 - 100).toFixed(2) === NaN
      ? "--"
      : ((value / total) * 100 - 100).toFixed(2);
  } else {
    return 0; // or you can handle this case differently, depending on your requirement
  }
}

const IntelligenceNewDashboard = () => {
  const [period2ShowDropDown, setPeriod2ShowDropDown] = useState(false);
  const [period1ShowDropDown, setPeriod1ShowDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [saveSelectedItem, setSaveSelectedItem] = useState([]);
  const selectedItemDropDown =
    saveSelectedItem &&
    saveSelectedItem?.map((item) => {
      return item?.value;
    });
  const fromDate = useIntelligenceDateRange().fromDate;
  const toDate = useIntelligenceDateRange().toDate;
  const [StreamlinecurrentPage, setStreamLineCurrentPage] = useState(1);
  const [RoomcurrentPage, setRoomCurrentPage] = useState(1);

  const currentDate = new Date();
  const [FirstPeriod, setFirstPeriod] = useState([
    {
      startDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        1
      ),
      endDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth() + 1,
        0
      ),
      key: "range1",
    },
  ]);
  const [SecondPeriod, setSecondPeriod] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartItem, setChartItem] = useState("Revenue");
  const [startEndPeriod1, setStartEndPeriod1] = useState([
    {
      startDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        1
      ),
      endDate: new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth() + 1,
        0
      ),
      key: "range1",
    },
  ]);
  const [startEndPeriod2, setStartEndPeriod2] = useState([
    {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      key: "range2",
    },
  ]);
  const [inputValue, setInputValue] = useState(
    `${format(new Date(startEndPeriod1[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod1[0].endDate), "dd-MMM-yy")}`
    // `${format(new Date(startEndPeriod1[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod1[0].endDate), "dd-MMM-yy")}`
  );
  const [inputValuePeriod2, setInputValuePeriod2] = useState(
    `${format(new Date(startEndPeriod2[0].startDate), "dd-MMM-yy")} - ${format(new Date(startEndPeriod2[0].endDate), "dd-MMM-yy")}`
  );

  const {
    data: getSourceOverview,
    isLoading: getSourceOverviewIsLoading,
    isFetching: getSourceOverviewIsFetching,
  } = useGetSourceOverviewQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
      startDate1: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
      endDate1: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
      startDate: format(new Date(SecondPeriod[0].startDate), "yyyy-MM-dd"),
      endDate: format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd"),
      source: selectedItemDropDown?.join(", "),
      pageNumber: StreamlinecurrentPage,
      roomPageNumber: RoomcurrentPage,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: overview,
    isLoading: overviewIsLoading,
    isFetching: overviewIsFetching,
  } = useGetNewDashboardOverviewQuery(
    {
      userId: UserId(),
      hId: CurrentProperty(),
    }
    // { refetchOnMountOrArgChange: true }
  );

  const {
    data: getMainOverView,
    isFetching: getMainOverViewIsFetching,
    isLoading: getMainOverViewIsLoading,
  } = useDashboardOverviewQuery(
    {
      hId: CurrentProperty(),
      userId: UserId(),
      chartData:
        chartItem === "ADR"
          ? "ADR"
          : chartItem === "Room Nights"
            ? "roomNights"
            : chartItem === "Revenue"
              ? "revenue"
              : chartItem || "revenue",
      // timePeriod: format(new Date(startDate), "yyyy-MM-dd"),
      // comparison: format(new Date(endDate), "yyyy-MM-dd"),
      startDate: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
      endDate: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
      startDate1: format(new Date(SecondPeriod[0].startDate), "yyyy-MM-dd"),
      endDate1: format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd"),
      source: selectedItemDropDown?.join(", "),
    },
    { refetchOnMountOrArgChange: FirstPeriod || SecondPeriod ? true : false }
  );

  const options = OtaOptions();
  const sourceData = getSourceOverview && getSourceOverview?.data?.sourceResult;
  const roomData = getSourceOverview && getSourceOverview?.data?.roomsResult;
  const overviewData = overview && overview?.data;
  const chartData = overview && overview?.data?.thisYearChartData;
  const previousYearChartData = overview && overview?.data?.lastYearChartData;
  useEffect(() => {
    if (
      overviewIsLoading ||
      getSourceOverviewIsLoading ||
      overviewIsFetching ||
      getSourceOverviewIsFetching ||
      getMainOverViewIsFetching ||
      getMainOverViewIsLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    overviewIsLoading,
    getSourceOverviewIsLoading,
    overviewIsFetching,
    getSourceOverviewIsFetching,
    getMainOverViewIsFetching,
    getMainOverViewIsLoading,
  ]);

  const overviewApiData = getMainOverView?.data && getMainOverView?.data;

  const semiDougnutChartData = {
    labels: ["Category 1"],
    series: overviewApiData ? [overviewApiData?.thisYearResult?.occupancy] : [],
  };

  const data = overviewApiData && [
    {
      title: "Revenue",
      value: formatCurrency(overviewApiData?.thisYearResult?.revenue),
      percentage: calculatePercentage(
        overviewApiData?.thisYearResult?.revenue,
        overviewApiData?.lastYearResult?.revenue
      ),
    },
    {
      title: "ADR",
      value: formatCurrency(overviewApiData?.thisYearResult?.ADR),
      percentage: calculatePercentage(
        overviewApiData?.thisYearResult?.ADR,
        overviewApiData?.lastYearResult?.ADR
      ),
    },
    {
      title: "Room Nights",
      value: overviewApiData?.thisYearResult?.roomNights,
      percentage: calculatePercentage(
        overviewApiData?.thisYearResult?.roomNights,
        overviewApiData?.lastYearResult?.roomNights
      ),
    },
  ];

  const chartDataLabelOfMainChartThisYear =
    overviewApiData &&
    overviewApiData?.thisYearChartData?.date?.map((item) => {
      return format(new Date(item), "dd-MMM-yy");
    });
  const chartDataValueOfMainChartThisYear =
    overviewApiData && overviewApiData?.thisYearChartData?.value;

  const chartDataLabelOfMainChartLastYear =
    overviewApiData &&
    overviewApiData?.lastYearChartData?.date?.map((item) => {
      return format(new Date(item), "dd-MMM-yy");
    });

  const chartDataValueOfMainChartLastYear =
    overviewApiData && overviewApiData?.lastYearChartData?.value;

  const handleSave = () => {
    setFirstPeriod(startEndPeriod1);
    setSecondPeriod(startEndPeriod2);
    setSaveSelectedItem(selectedItem);
  };

  const [periodOneCalender, setPeriodOneCalender] = useState(false);
  const [periodTwoCalender, setPeriodTwoCalender] = useState(false);
  const ref = useRef();
  const ref2 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPeriodOneCalender(false);
        setPeriod1ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref2?.current && !ref2?.current.contains(event.target)) {
        setPeriodTwoCalender(false);
        setPeriod2ShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref2]);

  const period1Fromatted = `${format(
    new Date(SecondPeriod[0].startDate),
    "dd MMM yy"
  )} - ${format(new Date(SecondPeriod[0].endDate), "dd MMM yy")}`;

  const period2Formatted = `${format(
    new Date(FirstPeriod[0].startDate),
    "dd MMM yy"
  )} - ${format(new Date(FirstPeriod[0].endDate), "dd MMM yy")}`;
  const isIpad = useIsIpad();
  const dateRange = useIntelligenceDateRange();

  const { data: sourceOverViewExpot } = useGetExportSourceOverviewQuery({
    userId: UserId(),
    hId: CurrentProperty(),
    startDate: format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd"),
    endDate: format(new Date(FirstPeriod[0].endDate), "yyyy-MM-dd"),
    startDate1: format(new Date(SecondPeriod[0].startDate), "yyyy-MM-dd"),
    endDate1: format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd"),
    source: selectedItemDropDown?.join(", "),
    pageNumber: StreamlinecurrentPage,
    roomPageNumber: RoomcurrentPage,
  });

  const chartRef = useRef();
  const tableRef = useRef();

  const generatePDF = () => {
    const pdf = new jsPDF();

    // Capture chart
    html2canvas(chartRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1); // Reduce quality to 0.5
      pdf.addImage(imgData, "JPEG", 10, 10, 180, 80);

      // Capture table
      html2canvas(tableRef.current, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 1); // Reduce quality to 0.5
        pdf.addImage(imgData, "JPEG", 10, 100, 180, 60);
        pdf.save("chart_and_table.pdf");
      });
    });
  };

  return (
    <>
      <BackdropLoader loading={isLoading} />
      <div className="px-5 flex flex-col gap-4">
        <div className="py-4 flex items-center gap-2">
          <div>{/* <BackButton mainDashboard={true} /> */}</div>
          <div>
            <div className="text-white  dark:text-black text-[22px]">
              Welcome to Hotel Intelligence
            </div>
            <div className="text-white dark:text-black text-sm">
              Your property's performance
            </div>
          </div>
        </div>
        <div className="mt-0 w-[100%] ">
          <OverviewCard
            data={overviewData}
            chartData={chartData}
            asPer={overview?.data?.asPer}
            previousYearChartData={previousYearChartData}
          />
        </div>
        {/* Option Header */}
        <div
          className={`bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-lg p-4 flex justify-start    w-[100%] ${isIpad ? "flex-col items-baseline gap-4" : "justify-between items-center gap-2 flex-row"}`}
        >
          <div className="w-[20%]">
            <Dropdown
              placeHolder={"Select Source"}
              options={options}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          </div>
          <div className="flex gap-6 w-fit flex-col md:flex-row">
            <div className="flex justify-end  items-center !important gap-2 w-[100%]">
              <div className="h-5 w-5 bg-[#F6F5F2] rounded-full"></div>
              <div className="text-sm mx-auto text-white">From</div>
              <div className="flex justify-end" ref={ref}>
                <TimeRange
                  period={startEndPeriod1}
                  setPeriod={setStartEndPeriod1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  left={"-300px"}
                  setCalendarOpen={setPeriodOneCalender}
                  calendarOpen={periodOneCalender}
                  isFuture={false}
                  showDropDown={period1ShowDropDown}
                  setShowDropDown={setPeriod1ShowDropDown}
                  minDate={new Date(dateRange?.fromDate) || new Date()}
                  maxDate={new Date(dateRange?.toDate) || new Date()}
                />
              </div>
            </div>
            <div className="flex justify-between md:justify-end items-center gap-2 w-[100%]">
              <div className="h-5 w-5 cursor-pointer bg-[#FA7070] rounded-full"></div>
              <div className="text-sm text-white">To</div>
              <div className="flex justify-end " ref={ref2}>
                <TimeRange
                  period={startEndPeriod2}
                  setPeriod={setStartEndPeriod2}
                  inputValue={inputValuePeriod2}
                  setInputValue={setInputValuePeriod2}
                  left={"-380px"}
                  setCalendarOpen={setPeriodTwoCalender}
                  calendarOpen={periodTwoCalender}
                  isFuture={false}
                  showDropDown={period2ShowDropDown}
                  setShowDropDown={setPeriod2ShowDropDown}
                  minDate={new Date(dateRange?.fromDate) || new Date()}
                  maxDate={new Date(dateRange?.toDate) || new Date()}
                />
              </div>
            </div>
            <div
              onClick={handleSave}
              className="bg-blue-50 w-fit md:w-full outline outline-blue-500 rounded-lg px-4 flex justify-between items-center mt-1.5 h-[30px] text-blue-500 hover:bg-blue-500 hover:text-white duration-150 ease-in-out transition-all cursor-pointer"
            >
              Update
            </div>
          </div>
        </div>
        {/* Selectable Area Chart */}
        <div>
          <SelectableChart
            chartHeight={450}
            data={data}
            chartItem={chartItem}
            setChartItem={setChartItem}
            thisYearLabel={chartDataLabelOfMainChartThisYear}
            thisYearData={chartDataValueOfMainChartThisYear}
            lastYearLabel={chartDataLabelOfMainChartLastYear}
            lastYearValue={chartDataValueOfMainChartLastYear}
            lastYearData={getMainOverView?.data?.lastYearResult}
            periodStartDate={format(
              new Date(SecondPeriod[0].startDate),
              "dd MMM yy"
            )}
            periodEndDate={format(
              new Date(SecondPeriod[0].endDate),
              "dd MMM yy"
            )}
            period1StartDate={format(
              new Date(FirstPeriod[0].startDate),
              "dd MMM yy"
            )}
            period1EndDate={format(
              new Date(FirstPeriod[0].endDate),
              "dd MMM yy"
            )}
            xaxisShow={true}
            multiSeries={false}
            isIpad={isIpad}
          />
        </div>
        {/* Starting of cards */}
        <div className="flex items-center flex-col md:flex-row gap-4">
          <div className="w-full md:w-[30%] h-[310px] bg-newGradient dark:bg-lightGradient  drop-shadow-lg backdrop-blur-md rounded-lg">
            <div className=" py-2 text-sm flex justify-between w-[100%] px-5 items-center">
              <div className="flex w-[100%] items-center text-white justify-between">
                <p>Occupancy</p>
                <InfoToolTip
                  index={"occupancy"}
                  position={"left"}
                  content={
                    "The percentage of available rooms that are occupied in a given period."
                  }
                />
              </div>{" "}
            </div>
            <div className="relative">
              <div
                className={`text-[#ffffff] pt-1 left-5 w-[90%] flex justify-between absolute`}
              >
                <span className="text-white text-[16px]">
                  {period2Formatted}
                </span>

                <span className="text-[16px] text-white">
                  {overviewApiData?.lastYearResult?.occupancy}%
                </span>
              </div>
            </div>

            <div className="w-[100%] relative mt-5 flex items-center ">
              {overviewApiData?.thisYearResult?.occupancy && (
                <SemiDoughnutChart
                  series={[overviewApiData?.thisYearResult?.occupancy]}
                  percentage={overviewApiData?.thisYearResult?.occupancy}
                  data={semiDougnutChartData}
                  fillPercentage={100}
                  height={isIpad && 300}
                  width={isIpad && 300}
                />
              )}
              <div>
                <div
                  className={`text-[24px] text-white absolute ${isIpad ? "left-[34%]" : "left-[40%]"}  flex flex-col items-center`}
                >
                  <p>{overviewApiData?.thisYearResult?.occupancy}%</p>
                  <p className="text-[10px] mt-2 text-[#ffffff]">
                    {period1Fromatted}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-full md:w-[70%] ${isIpad ? "grid grid-cols-3 gap-4" : " flex flex-col md:flex-row items-center flex-wrap gap-4 justify-between"}`}
          >
            <Cards
              mainData={overviewApiData?.thisYearResult?.reservationCount}
              bottomData={`${overviewApiData?.lastYearResult?.reservationCount}`}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.reservationCount,
                overviewApiData?.lastYearResult?.reservationCount
              )}
              title={"Reservation"}
              index={"reservation"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "Total number of rooms booked for a specific period. Helps anticipate occupancy and plan resources efficiently."
              }
              isIpad={isIpad}
            />
            <Cards
              mainData={parseFloat(
                overviewApiData?.thisYearResult?.bookingPacePerDay
              ).toFixed(2)}
              bottomData={`${parseInt(overviewApiData?.lastYearResult?.bookingPacePerDay).toFixed(2) && parseInt(overviewApiData?.lastYearResult?.bookingPacePerDay).toFixed(2) != "NaN" ? parseFloat(overviewApiData?.lastYearResult?.bookingPacePerDay).toFixed(2) : "--"} `}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.bookingPacePerDay,
                overviewApiData?.lastYearResult?.bookingPacePerDay
              )}
              title={"Room Nights Per Day"}
              index={"bookingPace"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "Average number of room nights generated through booking daily."
              }
              isIpad={isIpad}
            />
            <Cards
              mainData={overviewApiData?.thisYearResult?.lead}
              bottomData={`${overviewApiData?.lastYearResult?.lead ? overviewApiData?.lastYearResult?.lead : "--"}  ${isIpad ? "D" : "Days"} `}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.lead,
                overviewApiData?.lastYearResult?.lead
              )}
              title={"Lead Time"}
              index={"lead"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "The duration between the date of booking and the actual stay date."
              }
              isIpad={isIpad}
            />
            <Cards
              mainData={formatCurrency(overviewApiData?.thisYearResult?.RevPAR)}
              bottomData={`${formatCurrency(overviewApiData?.lastYearResult?.RevPAR)} `}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.RevPAR,
                overviewApiData?.lastYearResult?.RevPAR
              )}
              title={"RevPAR"}
              index={"RevPAR"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "The total revenue generated per available room, calculated "
              }
              isIpad={isIpad}
            />
            <Cards
              mainData={overviewApiData?.thisYearResult?.BOB}
              bottomData={`${overviewApiData?.lastYearResult?.BOB ? overviewApiData?.lastYearResult?.BOB : "--"} `}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.BOB,
                overviewApiData?.lastYearResult?.BOB
              )}
              title={"BOB"}
              index={"bob"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "Sum of confirmed reservations for future dates. Provides insight into future revenue and allows for proactive planning."
              }
              isIpad={isIpad}
            />
            <Cards
              mainData={overviewApiData?.thisYearResult?.los}
              bottomData={`${overviewApiData?.lastYearResult?.los ? overviewApiData?.lastYearResult?.los : "--"} ${isIpad ? "D" : "Days"} `}
              title={"LOS"}
              percentageChange={calculatePercentage(
                overviewApiData?.thisYearResult?.los,
                overviewApiData?.lastYearResult?.los
              )}
              index={"los"}
              period1={period1Fromatted}
              period2={period2Formatted}
              tooltip={
                "The average duration of guest stays during the specified period."
              }
              isIpad={isIpad}
            />
          </div>
        </div>
        <div className="mb-4" ref={tableRef}>
          <NewTable
            data={getSourceOverview?.data?.sourceResult}
            filename={"Revenue, ADR, Room Nights by Source"}
            defaultSortColumn={"source"}
            startDate={format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd")}
            endDate={format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd")}
            currentPage={StreamlinecurrentPage}
            setCurrentPage={setStreamLineCurrentPage}
            totalpages={
              getSourceOverview?.data?.sourceResult[0] &&
              getSourceOverview?.data?.sourceResult[0]?.totalPages
            }
            totalCount={getSourceOverview?.data?.sourceResult[0]?.count}
            totalData={getSourceOverview?.data?.resultData}
            downloadData={sourceOverViewExpot?.data?.sourceResult}
            havePagination={true}
          />
        </div>
        {/* <div ref={chartRef}>
        <ChartComponent />
      </div> */}
        {/* <button onClick={generatePDF}>Generate PDF</button> */}
        <div className="mb-4" ref={tableRef}>
          <NewTable
            data={
              roomData &&
              roomData
                ?.slice()
                ?.sort((a, b) => b?.revenueThisYear - a?.revenueThisYear)
            }
            havePagination={false}
            room={true}
            filename={"Revenue, ADR, Room Nights by Room Types"}
            defaultSortColumn={"rooms"}
            startDate={format(new Date(FirstPeriod[0].startDate), "yyyy-MM-dd")}
            endDate={format(new Date(SecondPeriod[0].endDate), "yyyy-MM-dd")}
            currentPage={RoomcurrentPage}
            setCurrentPage={setRoomCurrentPage}
            totalpages={
              getSourceOverview?.data?.roomsResult[0] &&
              getSourceOverview?.data?.roomsResult[0]?.totalPages
            }
            totalCount={getSourceOverview?.data?.roomsResult[0]?.count}
            totalData={getSourceOverview?.data?.resultData}
            downloadData={sourceOverViewExpot?.data?.roomsResult}
          />
        </div>
      </div>
    </>
  );
};

export default IntelligenceNewDashboard;

export const Dropdown = ({
  placeHolder,
  selectedItem = [],
  setSelectedItem,
  checkBox,
  width,
  left,
  type = "",
  dropDownoptions,
}) => {
  const [showOption, setShowOption] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const ref = useRef();
  let typo = "seggregatedSource";
  const options = dropDownoptions
    ? dropDownoptions
    : OtaOptions((typo = { typo }));
  const selectedLabels =
    Array?.isArray(selectedItem) && selectedItem?.map((item) => item?.label);

  const handleAdd = (option) => {
    if (option.label === "Select All") {
      if (!selectAllChecked) {
        setSelectedItem(options);
      } else {
        setSelectedItem([]);
      }
      setSelectAllChecked(!selectAllChecked);
    } else {
      if (selectedLabels?.includes(option.label)) {
        const updatedSelection = selectedItem && selectedItem.filter(
          (item) => item.label !== option.label
        );
        setSelectedItem(updatedSelection);
      } else {
        setSelectedItem([...selectedItem, option]);
      }
    }
  };

  const handleDelete = (toDeleteName) => {
    const updatedSelection = selectedItem?.filter(
      (item) => item.label !== toDeleteName
    );
    setSelectedItem(updatedSelection);
  };

  useEffect(() => {
    if (
      options?.length > 0 && // Check if options is defined and has items
      selectedItem?.length === options?.length && // Check if selectedItem is defined and lengths match
      !selectAllChecked // Ensure selectAllChecked is false
    ) {
      setSelectAllChecked(true);
    }
  }, [options, selectedItem, selectAllChecked]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        setShowOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div className="relative" ref={ref}>
        <div
          className={` flex justify-between gap-4 items-center border-[#F0F0F0] bg-white/30 dark:bg-customSubDarkBg dark:text-white text-sm text-[#ffffff] ${selectedItem && selectedItem?.length ? "px-2 py-1" : "p-2"}  rounded-lg cursor-pointer ${width ? width : "w-[300px]"}`}
          onClick={() => setShowOption(!showOption)}
        >
          {Array.isArray(selectedItem) &&
            placeHolder &&
            selectedItem?.length === 0 &&
            placeHolder}
          {Array.isArray(selectedItem) &&
            !placeHolder &&
            selectedItem?.length === 0 &&
            "Select Source"}

          <div className="flex gap-4">
            {Array.isArray(selectedItem) && selectedItem?.length > 0 && (
              <div className="bg-white text-sm px-2 py-1 text-black rounded-lg flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <div className="truncate max-w-[80px]">
                    {Array.isArray(selectedItem) && selectedItem[0]?.label}
                  </div>
                </div>
                <div
                  className="text-gray-500"
                  onClick={() => {
                    handleDelete(selectedItem[0]?.label);
                  }}
                >
                  X
                </div>
              </div>
            )}
            {Array.isArray(selectedItem) && selectedItem?.length > 1 && (
              <div className="flex items-center gap-2 text-sm text-blue-500  bg-white px-2 py-1 rounded-lg whitespace-nowrap">
                <div>{selectedItem && selectedItem?.length} +</div>
                <div onClick={() => setSelectedItem([])}>
                  <AiFillCloseCircle />
                </div>
              </div>
            )}
          </div>

          <div className="relative right-0">
            <AiOutlineDown
              className={`${showOption ? "rotate-180" : ""} transition-all ease-in-out duration-150`}
            />
          </div>
        </div>
        {showOption && (
          <div
            className="absolute bg-gray-50 w-[300px] rounded-lg mt-2 text-mainLight dark:bg-gray-500 100  z-50"
            style={{ left: `${left}` }}
          >
            <div
              className="w-[100%] py-1 px-2 text-sm text-blue-500 cursor-default text-end "
              onClick={() => {
                setSelectedItem([]);
                setShowOption(false);
              }}
            >
              Clear All
            </div>
            <div className="h-[180px] overflow-y-scroll scrollHide border rounded-lg">
              <div
                key="select-all"
                className="p-2 text-sm flex sticky top-0 bg-white justify-start items-center gap-2 text-[#5b5e65] hover:bg-gray-50 cursor-pointer"
              >
                <input
                  className=""
                  type="checkbox"
                  onClick={() => {
                    handleAdd({ label: "Select All" });
                    setShowOption(false);
                  }}
                  checked={selectAllChecked}
                  id="selectAll"
                />
                <label className="cursor-pointer" htmlFor="selectAll">
                  Select All
                </label>
              </div>
              {options?.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="text-sm dark:text-mainDark flex text-mainLight dark:bg-gray-500  px-2 dark:hover:bg-gray-400 hover:bg-gray-100 py-2 border-b gap-2"
                  >
                    <input
                      className=""
                      type="checkbox"
                      onClick={() => handleAdd(option)}
                      id={option?.label}
                      checked={
                        selectedLabels && selectedLabels?.includes(option.label)
                      }
                    />
                    <label className="cursor-pointer" htmlFor={option?.label}>
                      {option?.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const Cards = ({
  mainData,
  title,
  bottomData,
  height,
  width,
  percentageChange,
  index,
  period1,
  period2,
  tooltip,
  isIpad,
}) => {
  return (
    <div
      className={`bg-newGradient dark:bg-lightGradient  p-4 ${width ? `w-[${width}%]` : isIpad ? "w-[100%]" : "w-full md:w-[32%]"} rounded-lg`}
      style={{ height: `${height}px` }}
    >
      <div className="flex justify-between items-center">
        <div className="text-sm text-white">{title}</div>
        <div>
          <InfoToolTip content={tooltip} index={index} />
        </div>
      </div>
      <div className="text-[19px] mt-1 flex items-center justify-between gap-2 font-medium text-[#ffffff]">
        <div>
          {mainData === NaN || mainData === "NaN" || !mainData
            ? "--"
            : mainData}
        </div>
        <div
          className={`text-sm ${percentageChange && percentageChange.toString().includes("-") ? "text-red-500" : "text-[#29CC39]"} flex `}
        >
          {percentageChange ? percentageChange : "--"}%
          <img
            src={
              percentageChange && percentageChange.toString().includes("-")
                ? down
                : upIcon
            }
            className={`ml-1`}
          />
        </div>{" "}
      </div>
      <div className="text-[10px] text-[#ffffff] mb-2">{period1}</div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-[#ffffff] text-sm">From</div>
          <div className="text-[10px] text-[#ffffff] mb-1">{period2}</div>
        </div>
        <div className="text-sm text-white">
          {!bottomData ||
          bottomData === NaN ||
          bottomData === "NaN" ||
          bottomData === undefined ||
          bottomData === "undefined"
            ? "--"
            : bottomData}
        </div>
      </div>
    </div>
  );
};

export const SelectableChart = ({
  chartHeight,
  data,
  chartItem,
  setChartItem,
  thisYearLabel,
  thisYearData,
  lastYearLabel,
  lastYearValue,
  previous,
  chartData,
  downloadButtonData,
  lastYearData,
  periodStartDate,
  periodEndDate,
  period1StartDate,
  period1EndDate,
  islastYear,
  width1,
  width2,
  multiSeries,
  multiSeriesData,
  xaxisShow,
  isIpad,
}) => {
  const [multiSeriesDataa, setMultiSeriesData] = useState();

  useEffect(() => {
    if (multiSeriesData && multiSeries) {
      setMultiSeriesData(multiSeriesData);
    }
  }, [multiSeriesData]);
  console.log(thisYearLabel)
  console.log(thisYearData)
  console.log(lastYearLabel)
  console.log(lastYearValue)
  console.log(lastYearData)
  console.log(multiSeriesDataa)
  return (
    <>
      {data ? (
        <div className="bg-newGradient dark:bg-lightGradient  p-4 rounded-lg flex justify-between gap-4 w-[100%]">
          <div
            className={`${isIpad ? "w-[30%]" : "w-[20%]"} flex flex-col gap-6`}
            style={{ width: width1 && `${width1}%` }}
          >
            {Array.isArray(data) &&
              data?.map((item) => {
                return (
                  <div
                    className={`px-4 py-4  ${item?.title === chartItem ? "border border-[#68B7FF] bg-[#2d485582] dark:bg-[#2d485582]" : " bg-white/20 dark:bg-[#81bbd782]"}   transition-all duration-100 ease-in-out rounded-lg cursor-pointer`}
                    onClick={() => setChartItem(item?.title)}
                  >
                    <div className="flex justify-between items-center">
                      <div className="text-lg text-[#ffffff]">
                        {item?.title}
                      </div>
                      <div>
                        <InfoToolTip
                          content={
                            item?.title === "Revenue"
                              ? "A comprehensive overview of key preformance metrics for revenue during the specified period."
                              : item?.title === "ADR"
                                ? "A detailed look at Average Daily Rate (ADR) metrics for the specified period."
                                : item?.title === "Room Nights" &&
                                  "An in-depth analysis of room nights metrics for the specified period."
                          }
                          position={"top"}
                          index={chartItem}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between mt-2 gap-2">
                      <div className="text-xs text-white md:text-[1.2vw] ">
                        {" "}
                        {item?.value}
                      </div>
                      <div
                        className={`${item?.percentage.toString().includes("-") ? "text-red-500 bg-red-100" : "text-green-500 bg-green-100"} text-sm p-1 rounded-md`}
                      >
                        {islastYear
                          ? item?.percentage.toFixed(2)
                          : item.percentage}{" "}
                        %
                      </div>
                    </div>
                    <div className="text-[10px] text-[#ffffff]">
                      {" "}
                      {periodStartDate} - {periodEndDate}
                    </div>
                    <div className="text-[12px] text-[#ffffff] mt-1"> </div>
                    <div className="flex justify-between items-center mt-2">
                      <div>
                        <div className="text-[14px] text-white">
                          {islastYear ? "Past Year" : "From"}
                        </div>
                        <div className="text-[10px] text-[#ffffff]">
                          {" "}
                          {period1StartDate} - {period1EndDate}
                        </div>
                      </div>
                      <div
                        className="text-[2vw] md:text-[1vw] mt-2 text-[#ffffff]"
                        title="Previous Year"
                      >
                        {item?.title.toLowerCase() === "revenue"
                          ? formatCurrency(lastYearData?.revenue)
                          : item?.title.toLowerCase() === "adr"
                            ? formatCurrency(lastYearData?.ADR)
                            : item?.title.toLowerCase() === "room nights" &&
                              lastYearData?.roomNights}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div
            className={`${isIpad ? "w-[70%] h-full" : "w-[80%] min-h-full"}`}
            style={{ width: width2 && `${width2}%` }}
          >
            {lastYearData ||
            lastYearLabel ||
            thisYearData ||
            thisYearLabel ||
            !multiSeries ||
            (multiSeries && multiSeriesDataa) ? (
              <AreaChart
                xaxisShow={xaxisShow}
                height={chartHeight}
                dashed={false}
                yaxisShow={true}
                currentSeriesData={Array.isArray(thisYearData) && thisYearData}
                currentSeriesName={
                  !multiSeries && lastYearLabel?.length > thisYearLabel?.length
                    ? lastYearLabel
                    : thisYearLabel
                }
                pastSeriesData={Array.isArray(lastYearValue) && lastYearValue}
                pastSeriesName={Array.isArray(lastYearLabel) && lastYearLabel}
                downloadButtonData={downloadButtonData}
                fileName={"Intelligence Report"}
                isCurrency={chartItem === "Room Nights" ? false : true}
                showSingleSeries={false}
                multiSeries={multiSeries}
                multiSeriesData={multiSeriesDataa}
                chartItem={chartItem}
                isFormat={true}
                textWhite
              />
            ) : (
              <div className="h-full w-full flex justify-center items-center">
                <NoDataFound />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-[500px]  w-[100%]">
          <CustomSkeleton height={500} />
        </div>
      )}
    </>
  );
};

const OverviewCard = ({ data, chartData, asPer, previousYearChartData }) => {
  const Card = ({
    previous,
    current,
    percentageChange,
    title,
    label,
    data,
    isUp,
    pastYearData,
  }) => {
    return (
      <div className="w-[40%] md:w-[25%] flex items-start">
        <div className="w-[60%] flex items-between flex-col gap-2">
          <div className="text-[13px] whitespace-nowrap text-[#ffffff]">
            {title}
          </div>{" "}
          <div className="flex items-center mt-1 gap-2">
            <div
              className="text-xs md:text-[1.2vw] text-white whitespace-nowrap"
              title="This Year"
            >
              {current}
            </div>
            <div
              className={` ${current === previous ? "text-white bg-gray-50/30" : isUp ? "bg-[#ECFDF5] text-[#29CC39]" : "text-red-500 bg-red-100"}  text-xs p-1 py-1 whitespace-nowrap rounded-md flex items-center gap-2`}
            >
              {current !== previous && (
                <AiOutlineCaretUp className={`${isUp ? "" : "rotate-180"}`} />
              )}
              <span> {percentageChange} %</span>
              {/* <span> {calculateDifference(current,previous)}</span> */}
            </div>
          </div>
          <div
            className="text-xs md:text-[1vw] text-[#ffffff]"
            title="Previous Year"
          >
            {previous}
          </div>
        </div>
        <div className="-mt-8 w-[100%] ">
          <AreaChart
            yaxisShow={false}
            xaxisShow={false}
            // showSingleSeries={true}
            filled={false}
            pastSeriesData={pastYearData}
            currentSeriesData={data}
            currentSeriesName={label}
            height={100}
            currentLabel={title}
            isCurrency={title === "Revenue" || title === "ADR" ? true : false}
            isFormat={true}
            tooltipIsFormat={true}
            showSingleSeries={false}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="p-4 bg-newGradient dark:bg-lightGradient drop-shadow-lg backdrop-blur-md rounded-lg h-[200px]  ">
      <div className="flex justify-between items-start">
        <div className="mb-4 text-white">
          Today’s Overview <span>vs Last Year</span> {asPer}
        </div>
        <div>
          <InfoToolTip
            content={
              "This feature lets you compare today's performance metrics with data from the same day last year."
            }
            position={"left"}
          />
        </div>
      </div>

      <div className="flex gap-6 w-[100%] h-[83%] md:h-auto overflow-x-scroll overflow-y-visible no-scrollbar md:overflow-x-visible ">
        <Card
          title={"Revenue"}
          current={formatCurrency(data?.thisYearResult?.revenue)}
          previous={formatCurrency(data?.lastYearResult?.revenue)}
          percentageChange={calculatePercentage(
            data?.thisYearResult?.revenue,
            data?.lastYearResult?.revenue
          )}
          isUp={
            data?.thisYearResult?.revenue > data?.lastYearResult?.revenue
              ? true
              : false
          }
          data={filterChartDataByName(chartData, "revenue")}
          label={filterChartDataByName(chartData, "month")}
          pastYearData={filterChartDataByName(previousYearChartData, "revenue")}
        />
        <Card
          title={"Room Nights"}
          current={data?.thisYearResult?.roomNights}
          previous={data?.lastYearResult?.roomNights}
          percentageChange={calculatePercentage(
            data?.thisYearResult?.roomNights,
            data?.lastYearResult?.roomNights
          )}
          data={filterChartDataByName(chartData, "roomNights")}
          label={filterChartDataByName(chartData, "month")}
          pastYearData={filterChartDataByName(
            previousYearChartData,
            "roomNights"
          )}
          isUp={
            data?.thisYearResult?.roomNights > data?.lastYearResult?.roomNights
              ? true
              : false
          }
        />
        <Card
          title={"ADR"}
          current={formatCurrency(data?.thisYearResult?.ADR)}
          previous={formatCurrency(data?.lastYearResult?.ADR)}
          percentageChange={calculatePercentage(
            data?.thisYearResult?.ADR,
            data?.lastYearResult?.ADR
          )}
          data={filterChartDataByName(chartData, "ADR")}
          label={filterChartDataByName(chartData, "month")}
          isUp={
            data?.thisYearResult?.ADR > data?.lastYearResult?.ADR ? true : false
          }
          pastYearData={filterChartDataByName(previousYearChartData, "ADR")}
        />
        <Card
          title={"Cancellation"}
          current={data?.thisYearResult?.cancellation}
          previous={data?.lastYearResult?.cancellation}
          percentageChange={calculatePercentage(
            data?.thisYearResult?.cancellation,
            data?.lastYearResult?.cancellation
          )}
          data={filterChartDataByName(chartData, "cancellation")}
          label={filterChartDataByName(chartData, "month")}
          isUp={
            data?.thisYearResult?.cancellation >
            data?.lastYearResult?.cancellation
              ? false
              : true
          }
          pastYearData={filterChartDataByName(
            previousYearChartData,
            "cancellation"
          )}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/icons/Group 356.svg";
import arrowUp from "../../assets/icons/uparrow.svg";
import { format } from "date-fns";
import { formatDateWWeek, isDate } from "../../utils/dateFormat";
import { convertDateToStringDate } from "../../components/ExcelDownload/RateSuggestion/RateSuggestionExcel";
import formatCurrency from "../../utils/formatCurrency";
import { Tooltip } from "react-tooltip";
import { formatDate } from "../dashboard/DownloadExcel/formatDate";
import NoDataFound from "../../components/noData";

const NewRateChangeReportTable = ({
  data,
  room,
  isToggled,
  filename,
  defaultSortColumn,
  label,
  isExpanded,
  source,
  onExpand,
  function1,
  function2,
  fullHeight
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const rowsPerPage = 5; // Change this value as per your requirement
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  let currentRows = [];

  if (Array.isArray(data)) {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    currentRows = data;
    // .slice(indexOfFirstRow, indexOfLastRow);
  } else {
  }

  useEffect(() => {
    if (defaultSortColumn) {
      setSortColumn(defaultSortColumn);
    }
  }, [defaultSortColumn]);

  const sortedData = currentRows.slice().sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (valueA < valueB) return sortDirection === "asc" ? 1 : -1;
      if (valueA > valueB) return sortDirection === "asc" ? -1 : 1;
    }
    return 0;
  });

  return (
    <div
      onClick={onExpand}
      className={`w-auto h-fit flex flex-col bg-newGradient dark:bg-lightGradient border rounded-lg  cursor-pointer my-[10px]`}
    >
      <div className="w-[100%] flex p-2 justify-center items-center">
        <div className="w-[50%] flex flex-col  text-white  justify-around">
          <div className="pl-4">
            <p className=" text-[16px]">{label}</p>
            <p className=" text-sm">{data?.body?.length} results found</p>
          </div>
        </div>
        <div className="w-[50%] flex justify-end pr-6">
          <img src={isExpanded ? arrowUp : arrowDown} alt="" />
        </div>
      </div>
      {isExpanded && (
        <>
          {data ? (
            <div className={`text-white w-[100%] `}>
              <div
                className={`w-[100%] border-b-4  ${label === "Arrival This Month" ? `flex flex-row` : `flex flex-row `} `}
              >
                {data?.header &&
                  data?.header.length > 0 &&
                  data?.header.map((headerItems, id) => {
                    return (
                      <div
                        key={id}
                        className={`w-[100%] h-[100%] ${id === 0 && ""} text-start text-base font-medium truncate`}
                      >
                        {typeof headerItems === "string" ? (
                          <div
                            className={`w-[${100 / data?.header && data?.header?.length}%] h-[100%]  `}
                          >
                            <p
                              className="w-[100%] h-[100%] whitespace-nowrap truncate text-center  text-base font-medium py-2 px-1  border-x-[0.2px] border-y-[0.2px]"
                              data-tooltip-content={headerItems}
                              data-tooltip-id={headerItems}
                            >
                              {headerItems}
                            </p>
                            <Tooltip place="top" id={headerItems} />
                          </div>
                        ) : (
                          <div
                            className={`w-[${100 / data?.header && data?.header?.length}%] h-[100%]  flex flex-col `}
                          >
                            <p
                              className="w-[100%] whitespace-nowrap truncate text-center text-base font-medium py-2 px-1  border-x-[0.2px] border-y-[0.2px]"
                              data-tooltip-content={headerItems?.parent}
                              data-tooltip-id={headerItems?.parent}
                            >
                              {headerItems?.parent}
                            </p>
                            <Tooltip place="top" id={headerItems?.parent} />
                            <div
                              className={`w-[100%] grid grid-cols-${headerItems?.child && headerItems?.child?.length}`}
                            >
                              {headerItems?.child &&
                                headerItems?.child.length > 0 &&
                                headerItems?.child.map(
                                  (headerItemsChild, id) => {
                                    return (
                                      <div
                                        key={id}
                                        className={`w-[100%]   text-start text-base font-medium`}
                                      >
                                        <p
                                          className="w-[100%] whitespace-nowrap text-start text-base font-medium py-2 px-1 truncate border-x-[0.2px] border-y-[0.2px]"
                                          data-tooltip-content={
                                            headerItemsChild
                                          }
                                          data-tooltip-id={headerItemsChild}
                                        >
                                          {headerItemsChild}
                                        </p>
                                        <Tooltip
                                          place="top"
                                          id={headerItemsChild}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="w-[100%] flex flex-col">
                <div class="h-[100%] scroll-container">
                  {data &&
                    data?.body &&
                    data?.body.map((item, index) => {
                      return (
                        <div key={index}>
                          <DataRow
                            key={index}
                            item={item}
                            index={indexOfFirstRow + index}
                            selectedRows={selectedRows}
                            room={room}
                            label={label}
                            data={data}
                            function1={function1}
                            function2={function2}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <NoDataFound isWhite={true} />
          )}
        </>
      )}
    </div>
  );
};

export default NewRateChangeReportTable;

const DataRow = ({
  data,
  room,
  item,
  index,
  selectedRows,
  setSelectedIndex,
  arrival = true,
  night,
  reservation,
  label,
  function1,
  function2
}) => {
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  const color = [
    "#1abc9c",
    "#f1c40f",
    "#2ecc71",
    "#2980b9",
    "#fad390",
    "#f8c291",
    "#60a3bc",
    "#4a69bd",
  ];

  const isSelected = selectedRows.includes(index);

  if (isSelected) {
  }
  function getSevenDaysForward(date) {
    // Ensure the date is a valid Date object
    if (!(date instanceof Date)) {
      throw new Error(
        "Invalid date format. Please provide a valid Date object."
      );
    }

    // Create a new Date object and add 7 days
    const sevenDaysForward = new Date(date);
    sevenDaysForward.setDate(sevenDaysForward.getDate() + 6);

    return sevenDaysForward;
  }
  console.log(item);
  return (
    <div className="flex flex-col " id={index}>
      <div
        className={`w-[100%]    ${label === "Arrival This Month" ? `flex flex-row` : `flex flex-row`} `}
      >
        {item &&
          item.map((itemObj, index1) => {
            return (
              <>
                <div
                  key={index1}
                  className={` ${index1 === 0 && ""}   h-[100%] text-start text-base font-medium`}
                  style={{
                    width: `${Math.ceil(100 / (item && item?.length))}%`,
                  }}
                >
                  {typeof itemObj.value === "string" ||
                  typeof itemObj.value === "number" ? (
                    <div
                      className={`w-[100%] ${index1 === 0 && ""}   h-[100%]`}
                    >
                      <p
                        className={`text-start text-base font-medium  h-[100%] w-[100%] py-2 px-1 truncate border-x-[0.2px] border-y-[0.2px] `}
                        data-tooltip-content={itemObj.value}
                        data-tooltip-id={itemObj.value}
                      >
                        {itemObj.value === null ||
                        itemObj.value === undefined ? (
                          <>{"-"}</>
                        ) : itemObj.type === "date" ? (
                          <>{formatDateWWeek(itemObj.value)}</>
                        ) : itemObj.type === "weekDate" ? (
                          <div className="flex flex-col gap-0">
                            <p>Week {index + 1}</p>
                            <p className="text-[10px]">
                              Starts from - {formatDate(itemObj.value)} to{" "}
                              {formatDate(
                                getSevenDaysForward(new Date(itemObj.value))
                              )}
                            </p>
                          </div>
                        ) : itemObj.type === "string" ? (
                          <>{itemObj.value}</>
                        ) : itemObj.type === "rate" ? (
                          <>{formatCurrency(Math.ceil(itemObj.value))}</>
                        ) : itemObj.type === "float" ? (
                          <>{Math.ceil(itemObj.value)}</>
                        ) : itemObj.type === "percent" ? (
                          <>{Math.ceil(itemObj.value)}%</>
                        ) : itemObj.type === "changeStatus" ? (
                          <>{itemObj.value}</>
                        ) : itemObj.type === "int" ? (
                          itemObj.value === "-" ? (
                            <>--</>
                          ) : (
                            <>{Math.ceil(itemObj.value)}</>
                          )
                        ) : (
                          <>{itemObj.value}</>
                        )}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={` h-[100%]  grid grid-cols-${itemObj && itemObj?.child?.length}`}
                    >
                      {itemObj?.child &&
                        itemObj?.child.map((itemObjId, itemObjindex) => {
                          return (
                            <div className={`h-[100%] w-[100%]`}>
                              <p
                                key={itemObjindex}
                                className={`text-start ${itemObjId.type === "changeStatus" && (itemObjId.value === "Change" ? "bg-rose-500" : "bg-green-500")} h-[100%] w-[100%] text-base font-medium py-2 px-1 truncate border-x-[0.2px] border-y-[0.2px]`}
                                data-tooltip-content={itemObjId.value}
                                data-tooltip-id={itemObjId.value}
                              >
                                {itemObjId.value === null ||
                                itemObjId.value === undefined ? (
                                  <>{"-"}</>
                                ) : itemObjId.type === "date" ? (
                                  <>{formatDateWWeek(itemObjId.value)}</>
                                ) : itemObj.type === "weekDate" ? (
                                  <div className="flex flex-col gap-0">
                                    <p>Week {itemObjindex + 1}</p>
                                    <p className="text-[10px]">
                                      Starts from - {formatDate(itemObj.value)}{" "}
                                      to{" "}
                                      {formatDate(
                                        getSevenDaysForward(
                                          new Date(itemObj.value)
                                        )
                                      )}
                                    </p>
                                  </div>
                                ) : itemObjId.type === "string" ? (
                                  <>{itemObjId.value}</>
                                ) : itemObjId.type === "float" ? (
                                  itemObjId.value === "-" ? (
                                    <>--</>
                                  ) : (
                                    <>{Math.ceil(itemObjId.value)}</>
                                  )
                                ) : itemObjId.type === "int" ? (
                                  itemObjId.value === "-" ? (
                                    <>--</>
                                  ) : (
                                    <>{Math.ceil(Number(itemObjId.value))}</>
                                  )
                                ) : itemObjId.type === "rate" ? (
                                  <>
                                    {formatCurrency(Math.ceil(itemObjId.value))}
                                  </>
                                ) : itemObjId.type === "percent" ? (
                                  <>{Math.ceil(itemObjId.value)}%</>
                                ) : itemObjId.type === "dynamicAmountButton" ? (
                                  <button className="underline text-white" onClick={() => {function1(index,index1,itemObjindex)}}>
                                    {formatCurrency(Math.ceil(itemObjId.value))}
                                  </button>
                                ) : itemObjId.type ===
                                  "averageCompsetAmountButton" ? (
                                  <button className="underline  text-white" onClick={() => {function2(index,index1,itemObjindex)}}>
                                    {formatCurrency(Math.ceil(itemObjId.value))}
                                  </button>
                                ) : (
                                  <>{itemObjId.value}</>
                                )}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};
